/* eslint-disable @typescript-eslint/no-unused-vars */
import {VStack, Text, Button, Divider, Center} from '@chakra-ui/react';
import {FieldProps} from '@rjsf/utils';
import {FC, memo} from 'react';
import {useT} from '@progress-fe/core';

import {TColumnSpec, TColumnSpecItem, TColumnSpecsFormData} from './ColumnSpecsJsField.types';
import {SpecItem} from './components/SpecItem/SpecItem';

interface IProps extends FieldProps {
  formData: TColumnSpecsFormData;
}

const ColumnSpecsJsFieldFC: FC<IProps> = ({formData, schema, onChange}) => {
  const {t} = useT();

  const fieldConfig = formData.fieldConfig;

  const isEmpty = !formData.fieldValues.length;

  const handleChangeValue = (value: TColumnSpec[]) => {
    onChange({
      ...formData,
      fieldValues: value
    });
  };

  const handleAdd = () => {
    const valueCopy = [...formData.fieldValues];
    const items: TColumnSpecItem[] = Array(fieldConfig.specRowsNum).fill({
      element: null,
      parameter: null,
      value: null
    });

    valueCopy.push({order: valueCopy.length + 1, items});
    handleChangeValue(valueCopy);
  };

  const handleDelete = (deleteId: number) => {
    const deletedorder = formData.fieldValues[deleteId].order;

    // all specs that have higher oprer shiuls be decreased
    const newValue = formData.fieldValues
      .filter((_, idx) => {
        return idx !== deleteId;
      })
      .map((item) => {
        if (item.order > deletedorder) {
          --item.order;
        }
        return item;
      });
    handleChangeValue(newValue);
  };

  const handleSpecChanged = (idx: number, changedSpec: TColumnSpec) => {
    const valueCopy = [...formData.fieldValues];
    const existSpec = valueCopy[idx];
    const currentOrder = existSpec.order;

    // if order has been changed than switch order with another spec
    if (currentOrder !== changedSpec.order) {
      const specToSwitch = valueCopy.find((item) => item.order === changedSpec.order);
      if (specToSwitch) {
        specToSwitch.order = existSpec.order;
      }
    }

    valueCopy[idx] = changedSpec;

    handleChangeValue(valueCopy);
  };

  return (
    <VStack w="100%" gap="8px">
      {isEmpty && (
        <Center pb="40px" pt="40px">
          <Text>{t('jsfields.ColumnSpecsJsField.noItems')}</Text>
        </Center>
      )}

      {formData.fieldValues.map((spec, idx) => (
        <SpecItem
          key={idx}
          spec={spec}
          isReadonly={!!schema.readOnly}
          maxOrder={formData.fieldValues.length}
          onChange={(updatedSpec) => handleSpecChanged(idx, updatedSpec)}
          onDelete={() => handleDelete(idx)}
          elementsOptions={fieldConfig.elementsOptions}
          parametersOptions={fieldConfig.parametersOptions}
        />
      ))}
      {!schema.readOnly && (
        <>
          <Divider />
          <Button variant="ghost" size="smSquare" w="100%" onClick={handleAdd}>
            {t('jsfields.ColumnSpecsJsField.addSpec')}
          </Button>
        </>
      )}
    </VStack>
  );
};

export const ColumnSpecsJsField = memo(ColumnSpecsJsFieldFC);
