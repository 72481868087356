import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, IconButton} from '@chakra-ui/react';
import {Svg} from '@progress-fe/ui-kit';

interface IProps {
  isError: boolean;
  isDisabled: boolean;
  onClick?: () => void;
}

const ErrorIconButtonFC: FC<IProps> = ({isError, isDisabled, onClick}) => {
  return (
    <Box>
      <IconButton
        size="sm"
        aria-label=""
        isDisabled={isDisabled}
        variant={!isError ? 'ghost' : 'danger'}
        onClick={onClick}
        icon={
          <Box color={!isError ? 'black' : 'white'}>
            <Svg name="Error" />
          </Box>
        }
      />
    </Box>
  );
};

export const ErrorIconButton = observer(ErrorIconButtonFC);
