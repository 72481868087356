import {FC, memo, ReactElement, useEffect, useRef, useState} from 'react';
import {hasValue} from '@progress-fe/core';
import {
  InputProps,
  InputGroup,
  NumberInput,
  NumberInputField,
  InputLeftElement,
  InputRightAddon
} from '@chakra-ui/react';

import {useMinMax} from '../../../hooks';

interface IProps extends Omit<InputProps, 'onChange' | 'onBlur' | 'onFocus'> {
  exclusiveMin?: number;
  exclusiveMax?: number;
  isOnChangeOnlyOnBlur?: boolean;
  isFloat?: boolean;
  disabled?: boolean;
  clampValueOnBlur?: boolean;
  leftElement?: ReactElement | string;
  rightElement?: ReactElement | string;
  onChange?: (value: string | null) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const InputNumberFC: FC<IProps> = ({
  isOnChangeOnlyOnBlur,
  value,
  size,
  variant,
  disabled,
  isFloat,
  clampValueOnBlur = false,
  leftElement,
  rightElement,
  isInvalid,
  isReadOnly,
  onChange,
  onFocus,
  onBlur,
  ...props
}) => {
  const [validValue, setValidValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue(value?.toString() || '');
    setValidValue(value?.toString() || '');
  }, [value]);

  const {isNegativeAllowed, isMinMaxValid} = useMinMax({
    minValue: props.min,
    maxValue: props.max,
    exclusiveMinValue: props.exclusiveMin,
    exclusiveMaxValue: props.exclusiveMax
  });

  return (
    <InputGroup size={size}>
      {!!leftElement && <InputLeftElement>{leftElement}</InputLeftElement>}

      <NumberInput
        size={size}
        variant={variant}
        value={inputValue}
        isDisabled={disabled}
        isReadOnly={isReadOnly}
        isInvalid={
          (hasValue(value) && isNaN(Number(value))) || isInvalid || !isMinMaxValid(validValue)
        }
        clampValueOnBlur={clampValueOnBlur}
        sx={{width: '100%'}} // FYI: Without stepper
        onChange={(valueString) => {
          const rgx = /\./g;

          // skip second "." symbol
          const dotsCount = valueString.match(rgx)?.length || 0;
          if (dotsCount > 1 || (dotsCount === 1 && !isFloat)) return;

          // negative value is not allowed
          if (!isNegativeAllowed && valueString.startsWith('-')) return;

          // skip second "-" symbol
          if (valueString.includes('--')) return;

          // skip "+" symbol
          if (valueString.startsWith('+')) return;

          // skip "e" symbol
          if (valueString.startsWith('e')) return;

          // skip second "ee" symbol
          if (valueString.includes('ee')) return;

          // skip second ".e" symbol
          if (valueString.includes('.e')) return;

          setInputValue(valueString);
          if (!isOnChangeOnlyOnBlur) {
            onChange?.(valueString || null);
          }

          // skip when value ends with "." symbol
          if (valueString.endsWith('.')) return;

          // skip when value ends with "e" symbol
          if (valueString.endsWith('e')) return;

          // skip when value equals to "-" symbol
          if (valueString === '-') return;

          setValidValue(valueString);
        }}
        onFocus={onFocus}
        onBlur={() => {
          if (isOnChangeOnlyOnBlur) {
            onChange?.(validValue || null);
            setInputValue(validValue);
          }
          onBlur?.();
        }}
      >
        <NumberInputField ref={inputRef} sx={rightElement ? {borderRightRadius: '0'} : {}} />
      </NumberInput>

      {!!rightElement && (
        <InputRightAddon
          color="lightGray"
          justifyContent="flex-end"
          bg={disabled ? 'disabledGray' : 'white'}
        >
          {rightElement}
        </InputRightAddon>
      )}
    </InputGroup>
  );
};

export const InputNumber = memo(InputNumberFC);
