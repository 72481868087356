import {EElement} from '@progress-fe/core';

import {TModelDetailsSnapshotIn} from 'core/models';
/* SEPARATOR MODELS */
import * as MF_1_b from 'core/mocks/models/Separator/material_flows/1/peng_robinson_basic';
import * as MF_1_c from 'core/mocks/models/Separator/material_flows/1/peng_robinson_binary_coefficients';
import * as MF_2_b from 'core/mocks/models/Separator/material_flows/2/peng_robinson_basic';
import * as MF_2_c from 'core/mocks/models/Separator/material_flows/2/peng_robinson_binary_coefficients';
import * as SEP_2PH_b from 'core/mocks/models/Separator/2_phase_separator/2_phase_separator_basic';
import * as SEP_3PH_b from 'core/mocks/models/Separator/3_phase_separator/3_phase_separator_basic';
import * as COMPR_b from 'core/mocks/models/Separator/compressor/compressor_basic';
import * as COOLER_b from 'core/mocks/models/Separator/cooler/cooler_basic';
import * as VALVE_b from 'core/mocks/models/Separator/valve/valve_basic';
/* COKER MODELS */
import * as COKER_b from 'core/mocks/models/Coker/coker/coker_basic';
import * as COKER_s from 'core/mocks/models/Coker/coker/coker_settings';
import * as C_MF_1_b from 'core/mocks/models/Coker/material_flows/1/peng_robinson_basic';
import * as C_MF_1_c from 'core/mocks/models/Coker/material_flows/1/peng_robinson_binary_coefficients';

export const MODELS_LIST: {
  projectId: string;
  projectName: string;
  items: TModelDetailsSnapshotIn[];
}[] = [
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000001',
    projectName: 'Схема с сепараторами',
    items: [
      {
        uuid: '031CE246-0000-0000-0000-000000000001',
        type: EElement.MaterialFlowElement,
        name: 'Пенг-Робинсон-1',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: 'A1D11367-0000-0000-0000-000000000001',
            name: 'Базовые',
            uiSchema: MF_1_b.UI_SCHEMA_FLOW_MODEL_BASIC,
            schema: MF_1_b.SCHEMA_FLOW_MODEL_BASIC,
            formData: MF_1_b.FORM_DATA_FLOW_MODEL_BASIC
          },
          {
            id: 'A1D11367-0000-0000-0000-000000000002',
            name: 'Бинарные коэффициенты',
            uiSchema: MF_1_c.UI_SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS,
            schema: MF_1_c.SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS,
            formData: MF_1_c.FORM_DATA_PR_FLOW_MODEL_BINARY_COEFFICIENTS
          }
        ]
      },
      {
        uuid: '031CE246-0000-0000-0000-000000000002',
        type: EElement.MaterialFlowElement,
        name: 'Пенг-Робинсон-2',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: 'A1D11367-0000-0000-0000-000000000003',
            name: 'Базовые',
            uiSchema: MF_2_b.UI_SCHEMA_FLOW_MODEL_BASIC,
            schema: MF_2_b.SCHEMA_FLOW_MODEL_BASIC,
            formData: MF_2_b.FORM_DATA_FLOW_MODEL_BASIC
          },
          {
            id: 'A1D11367-0000-0000-0000-000000000004',
            name: 'Бинарные коэффициенты',
            uiSchema: MF_2_c.UI_SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS,
            schema: MF_2_c.SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS,
            formData: MF_2_c.FORM_DATA_PR_FLOW_MODEL_BINARY_COEFFICIENTS
          }
        ]
      },
      {
        uuid: '031CE246-0000-0000-0000-000000000003',
        type: EElement.TwoPhaseSeparatorElement,
        name: 'Сеп. 2-фазный-1',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: 'A1D11367-0000-0000-0000-000000000005',
            name: 'Базовые',
            uiSchema: SEP_2PH_b.UI_SCHEMA_2PHASE_SEPARATOR_BASIC,
            schema: SEP_2PH_b.SCHEMA_2PHASE_SEPARATOR_BASIC,
            formData: SEP_2PH_b.FORM_DATA_2PHASE_SEPARATOR_BASIC
          }
        ]
      },
      {
        uuid: '031CE246-0000-0000-0000-000000000004',
        type: EElement.ThreePhaseSeparatorElement,
        name: 'Сеп. 3-фазный-1',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: 'A1D11367-0000-0000-0000-000000000006',
            name: 'Базовые',
            uiSchema: SEP_3PH_b.UI_SCHEMA_3PHASE_SEPARATOR_BASIC,
            schema: SEP_3PH_b.SCHEMA_3PHASE_SEPARATOR_BASIC,
            formData: SEP_3PH_b.FORM_DATA_3PHASE_SEPARATOR_BASIC
          }
        ]
      },
      {
        uuid: '031CE246-0000-0000-0000-000000000005',
        type: EElement.CompressorElement,
        name: 'Компрессор-1',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: 'A1D11367-0000-0000-0000-000000000007',
            name: 'Базовые',
            uiSchema: COMPR_b.UI_SCHEMA_COMPRESSOR,
            schema: COMPR_b.SCHEMA_COMPRESSOR,
            formData: COMPR_b.FORM_DATA_COMPRESSOR
          }
        ]
      },
      {
        uuid: '031CE246-0000-0000-0000-000000000006',
        type: EElement.CoolerElement,
        name: 'Охладитель-1',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: 'A1D11367-0000-0000-0000-000000000008',
            name: 'Базовые',
            uiSchema: COOLER_b.UI_SCHEMA_COOLER_BASIC,
            schema: COOLER_b.SCHEMA_COOLER_BASIC,
            formData: COOLER_b.FORM_DATA_COOLER_BASIC
          }
        ]
      },
      {
        uuid: '031CE246-0000-0000-0000-000000000007',
        type: EElement.ValveElement,
        name: 'Клапан-1',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: 'A1D11367-0000-0000-0000-000000000009',
            name: 'Базовые',
            uiSchema: VALVE_b.UI_SCHEMA_VALVE_MODEL,
            schema: VALVE_b.SCHEMA_VALVE_MODEL,
            formData: VALVE_b.FORM_DATA_VALVE_MODEL
          }
        ]
      }
    ]
  },
  {
    projectId: '47CB2F0C-0000-0000-0000-000000000002',
    projectName: 'Схема с УЗК',
    items: [
      {
        uuid: 'D03B80FA-0000-0000-0000-000000000001',
        type: EElement.SingleParamChangeElement, // FIXME
        name: 'УЗК-1',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: '71B1CF82-0000-0000-0000-000000000001',
            name: 'Базовые',
            uiSchema: COKER_b.UI_SCHEMA_COKER_MODEL_BASIC,
            schema: COKER_b.SCHEMA_COKER_MODEL_BASIC,
            formData: COKER_b.FORM_DATA_COKER_MODEL_BASIC
          },
          {
            id: '71B1CF82-0000-0000-0000-000000000002',
            name: 'Настройки',
            uiSchema: COKER_s.UI_SCHEMA_COKER_MODEL_SETTINGS,
            schema: COKER_s.SCHEMA_COKER_MODEL_SETTINGS,
            formData: COKER_s.FORM_DATA_COKER_MODEL_SETTINGS
          }
        ]
      },
      {
        uuid: 'D03B80FA-0000-0000-0000-000000000002',
        type: EElement.MaterialFlowElement,
        name: 'Пенг-Робинсон-1',
        lastUpdated: new Date(),
        jsonSchemas: [
          {
            id: '71B1CF82-0000-0000-0000-000000000003',
            name: 'Базовые',
            uiSchema: C_MF_1_b.UI_SCHEMA_FLOW_MODEL_BASIC,
            schema: C_MF_1_b.SCHEMA_FLOW_MODEL_BASIC,
            formData: C_MF_1_b.FORM_DATA_FLOW_MODEL_BASIC
          },
          {
            id: '71B1CF82-0000-0000-0000-000000000004',
            name: 'Бинарные коэффициенты',
            uiSchema: C_MF_1_c.UI_SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS,
            schema: C_MF_1_c.SCHEMA_PR_FLOW_MODEL_BINARY_COEFFICIENTS,
            formData: C_MF_1_c.FORM_DATA_PR_FLOW_MODEL_BINARY_COEFFICIENTS
          }
        ]
      }
    ]
  }
];
