import {types, flow} from 'mobx-state-tree';
import {toastUtils} from '@progress-fe/ui-kit';
import {i18n} from '@progress-fe/core';

import {ResponseError} from 'api';
import {ERequestState} from 'core/enums';
import {UserManager} from 'core/services/oidc.service';

const UNAUTHORIZED_STATUS = 401;
const BAD_FIELD_STATUS = 400;

/**
 * This is utility model that responsible for:
 *
 * - fetching data
 * - showing errors
 * - keeping a request state
 * - adding auth details to every request
 * - handling auth errors
 */
const RequestModel = types
  .model('RequestModel', {
    showError: true,
    requestErrorCode: types.maybeNull(types.number),
    isCancellable: true,
    state: types.maybeNull(types.enumeration(Object.values(ERequestState)))
  })
  .actions((self) => {
    let cancel: AbortController | null = null;
    // @ts-ignore: MST-actions
    const actions = {
      // @ts-ignore: MST-actions
      send: flow(function* send<T, R>(
        action: (options: T, request?: RequestInit) => Promise<R>,
        options: T,
        request?: RequestInit
      ) {
        try {
          self.state = ERequestState.Pending;

          if (self.isCancellable) {
            if (cancel) {
              self.state = ERequestState.Canceled;
              actions.cancel(
                'Request cancelled: the same api call is being called multiple times subsequently'
              );
            }

            cancel = new AbortController();
          }

          const response: R = yield action(options, {
            ...request,
            ...(self.isCancellable ? {signal: cancel?.signal} : {})
          });

          //console.assert(!!response, 'Got empty response');
          self.state = ERequestState.Done;

          return response;
        } catch (error) {
          if (self.state !== ERequestState.Canceled) {
            console.error(error instanceof Error ? error.message : error);
            self.state = ERequestState.Error;

            /** handle errors **/
            if (error instanceof ResponseError) {
              return actions.handleApiError(error);
            }
          }
        } finally {
          if (cancel) {
            cancel = null;
          }
        }
      }),
      /**
       * To stop requests on route change for example
       */
      cancel(message?: string) {
        if (cancel) {
          cancel.abort(message || 'Request cancelled');
        }
      },
      handleApiError(error: ResponseError) {
        self.requestErrorCode = error.response?.status || null;

        if (self.showError) {
          toastUtils.error({
            title: i18n.t('error.server'),
            message: i18n.t('error.serverCode', {code: self.requestErrorCode})
          });
        }

        if (error?.response?.status === BAD_FIELD_STATUS) {
          return error?.response;
        }

        if (error?.response?.status === UNAUTHORIZED_STATUS) {
          UserManager.signinRedirect();
        }

        return undefined;
      }
    };
    return actions;
  })
  .views((self) => ({
    get isPending() {
      return self.state === ERequestState.Pending;
    },
    get isDone() {
      return self.state === ERequestState.Done;
    },
    get isError() {
      return self.state === ERequestState.Error;
    },
    get isCanceled() {
      return self.state === ERequestState.Canceled;
    },
    get isNotSend() {
      return self.state === null;
    },
    get isNotComplete() {
      return [ERequestState.Pending, null].includes(self.state);
    },
    get errorCode() {
      return self.requestErrorCode;
    }
  }));

export {RequestModel};
