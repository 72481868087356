import {observer} from 'mobx-react-lite';
import {ITab, JsForm} from '@progress-fe/ui-kit';
import {Tabs, TabList, Tab, TabPanels, TabPanel} from '@chakra-ui/react';
import {useState} from 'react';

import {TJsonSchemaModel} from 'core/models';

export interface IJsonSchemaTab extends ITab {
  jsonSchema: TJsonSchemaModel;
}

interface IProps<T> {
  tabs: IJsonSchemaTab[];
  lastUpdated?: Date;
  onChange: (formData: T, tabIdx: number, changedFieldPath?: string) => void;
}

const JsTabsFormWrapperFC = <T,>({tabs, lastUpdated, onChange}: IProps<T>) => {
  const [tabIndex, setTabIndex] = useState(0);

  const withTabs = tabs.length > 1;

  return (
    <Tabs variant="soft-rounded" index={tabIndex} onChange={setTabIndex}>
      {withTabs && (
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.index} isDisabled={tab.isDisabled}>
              {tab.name}
            </Tab>
          ))}
        </TabList>
      )}

      <TabPanels>
        {tabs.map(({jsonSchema}, index) => (
          <TabPanel key={`${index}`}>
            <JsForm
              key={`${index}_${lastUpdated?.toString()}`}
              schema={jsonSchema.schema}
              uiSchema={jsonSchema.uiSchema}
              formData={jsonSchema.formData as T}
              onChange={(formData, changedFieldPath) => {
                onChange(formData, index, changedFieldPath);
              }}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const JsTabsFormWrapper = observer(JsTabsFormWrapperFC);
