import {FC, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center, Flex, Grid, Text} from '@chakra-ui/react';
import {RFRenderEmpty} from '@progress-fe/rf-core';
import {EventEmitter} from '@progress-fe/core';

import {RFMenu} from 'ui-kit';
import {ECalculationResultType} from 'core/enums';
import {TResultDetailsModel} from 'core/models';

import {SliderResults, TableResults} from './components';

interface IProps {
  height: number;
  selectedEntityId: string | null;
  resultsDetails: TResultDetailsModel[];
}

const RFResultsZoneFC: FC<IProps> = ({height, selectedEntityId, resultsDetails}) => {
  const onSelectResult = useCallback((resultId: string) => {
    EventEmitter.emit('SelectItem', resultId);
  }, []);

  return (
    <Grid width="100%" gridTemplateColumns="48px 1fr" height={height}>
      <RFMenu menuItems={[]} height={height} />

      <Box width="100%" height={height}>
        <RFRenderEmpty />
      </Box>

      <Box position="absolute" left="48px" right="0" top="0" bottom="0">
        <Box pt="70px">
          <Flex
            gap="16px"
            p="0 0 24px 0"
            overflowY="auto"
            alignItems="center"
            flexDirection="column"
            height="calc(var(--height-layout) - 70px)"
          >
            {resultsDetails.map((result) => (
              <Box
                key={result.id}
                border="1px solid"
                borderRadius="8px"
                width="90%"
                maxWidth="600px"
                borderColor={selectedEntityId === result.id ? 'accent' : 'transparent'}
                onClick={() => onSelectResult(result.id)}
              >
                <Box key={result.id} border="1px solid" borderColor="transparent">
                  <Box
                    p="16px"
                    bg="white"
                    borderRadius="8px"
                    border="1px solid"
                    borderColor="border"
                  >
                    <Text fontSize="15px" fontWeight={600} color="black">
                      {result.namePropValue}
                    </Text>

                    {/* NO DATA */}
                    {!result.calculationData ? (
                      <Center h="70px">
                        <Text>Нет данных</Text>
                      </Center>
                    ) : (
                      <>
                        {/* TABLE RESULTS VIEW */}
                        {result.calculationData.result_type === ECalculationResultType.TABLE && (
                          <TableResults resultsInstance={result.calculationData} />
                        )}

                        {/* SLIDER RESULTS VIEW */}
                        {result.calculationData.result_type === ECalculationResultType.SLIDER && (
                          <SliderResults resultsInstance={result.calculationData} />
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </Flex>
        </Box>
      </Box>
    </Grid>
  );
};

export const RFResultsZone = observer(RFResultsZoneFC);
