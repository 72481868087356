import {FC} from 'react';
import {Box} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';
import {EStructureItem} from '@progress-fe/core';

import {useStore} from 'core/hooks';

import {TaskForm, ElementForms, SettingsForms, ModelForms, ResultForms} from './components';
import {SubEntityForm} from './components/SubEntityForm';

const ProjectFormsFC: FC = () => {
  const {techProcessStore} = useStore();
  const {selectedElement, selectedModel, selectedResult, uiState} = techProcessStore;
  const {projectTask, projectElements, projectModels} = techProcessStore;
  const {entityType, subEntityId, selectSubEntity} = uiState;
  const selectedEntity = selectedElement || selectedModel || selectedResult || null;

  return (
    <Box p="12px 8px" position="relative" h="100%">
      {subEntityId && selectedEntity && (
        //TODO: we need to get entity details from backend and pass it to the SubEntityForm
        <SubEntityForm
          selectedEntityName={selectedEntity.name}
          onBack={() => selectSubEntity(null)}
        />
      )}
      {entityType === EStructureItem.Settings && <SettingsForms />}

      {entityType === EStructureItem.Element && !!selectedElement && (
        <ElementForms
          key={selectedElement.uuid}
          elementDetails={selectedElement}
          onUpdateFormData={projectElements.updateElementFormData}
        />
      )}

      {entityType === EStructureItem.Model && !!selectedModel && (
        <ModelForms
          key={selectedModel.uuid}
          modelDetails={selectedModel}
          onUpdateFormData={projectModels.updateModelFormData}
        />
      )}

      {entityType === EStructureItem.Task && <TaskForm taskInstance={projectTask} />}

      {entityType === EStructureItem.Result && !!selectedResult && (
        <ResultForms key={selectedResult.id} resultDetails={selectedResult} />
      )}
    </Box>
  );
};

export const ProjectForms = observer(ProjectFormsFC);
