/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {RJSFSchemasWithModel} from './RJSFSchemasWithModel';
import {
  RJSFSchemasWithModelFromJSON,
  RJSFSchemasWithModelFromJSONTyped,
  RJSFSchemasWithModelToJSON
} from './RJSFSchemasWithModel';
import type {RJSFSchemas} from './RJSFSchemas';
import {RJSFSchemasFromJSON, RJSFSchemasFromJSONTyped, RJSFSchemasToJSON} from './RJSFSchemas';
import type {RJSFSchemasBase} from './RJSFSchemasBase';
import {
  RJSFSchemasBaseFromJSON,
  RJSFSchemasBaseFromJSONTyped,
  RJSFSchemasBaseToJSON
} from './RJSFSchemasBase';

/**
 *
 * @export
 * @interface CallbackRunResponseSchemasValue
 */
export interface CallbackRunResponseSchemasValue {
  /**
   *
   * @type {object}
   * @memberof CallbackRunResponseSchemasValue
   */
  jsonSchema: object;
  /**
   *
   * @type {object}
   * @memberof CallbackRunResponseSchemasValue
   */
  ui: object;
  /**
   *
   * @type {string}
   * @memberof CallbackRunResponseSchemasValue
   */
  tabName: string;
  /**
   *
   * @type {object}
   * @memberof CallbackRunResponseSchemasValue
   */
  data: object;
  /**
   *
   * @type {any}
   * @memberof CallbackRunResponseSchemasValue
   */
  model: any | null;
}

/**
 * Check if a given object implements the CallbackRunResponseSchemasValue interface.
 */
export function instanceOfCallbackRunResponseSchemasValue(
  value: object
): value is CallbackRunResponseSchemasValue {
  if (!('jsonSchema' in value) || value['jsonSchema'] === undefined) return false;
  if (!('ui' in value) || value['ui'] === undefined) return false;
  if (!('tabName' in value) || value['tabName'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  if (!('model' in value) || value['model'] === undefined) return false;
  return true;
}

export function CallbackRunResponseSchemasValueFromJSON(
  json: any
): CallbackRunResponseSchemasValue {
  return CallbackRunResponseSchemasValueFromJSONTyped(json, false);
}

export function CallbackRunResponseSchemasValueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CallbackRunResponseSchemasValue {
  if (json == null) {
    return json;
  }
  return {
    jsonSchema: json['json_schema'],
    ui: json['ui'],
    tabName: json['tab_name'],
    data: json['data'],
    model: json['model']
  };
}

export function CallbackRunResponseSchemasValueToJSON(
  value?: CallbackRunResponseSchemasValue | null
): any {
  if (value == null) {
    return value;
  }
  return {
    json_schema: value['jsonSchema'],
    ui: value['ui'],
    tab_name: value['tabName'],
    data: value['data'],
    model: value['model']
  };
}
