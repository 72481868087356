import {FC, ReactElement} from 'react';
import {observer} from 'mobx-react-lite';
import {Box, Center} from '@chakra-ui/react';

import {Header} from 'ui-kit/headers';
import {TUserModel} from 'core/models';

import {
  ErrorIconButton,
  PlayIconButton,
  RedoIconButton,
  SaveIconButton,
  UndoIconButton,
  UserIconButton,
  PlaySelectedIconButton
} from './components';

interface IProps {
  title: string;
  currentUser: TUserModel;
  isError: boolean;
  isRunning: boolean;
  lastSaveDate: Date | null;
  additionalActions?: ReactElement;
  onToggleLog: () => void;
  onRunCalculation: () => void;
  onSignOut: () => void;
  onSave: () => void;
}

const ProjectMainBarFC: FC<IProps> = ({
  title,
  currentUser,
  isError,
  isRunning,
  lastSaveDate,
  additionalActions,
  onRunCalculation,
  onToggleLog,
  onSignOut,
  onSave
}) => {
  return (
    <Header title={title}>
      <Center gap="8px">
        <UndoIconButton isDisabled={true} />
        <RedoIconButton isDisabled={true} />
        <Box w="1px" bg="border" h="32px" />

        {!!additionalActions && (
          <>
            {additionalActions} <Box w="1px" bg="border" h="32px" />
          </>
        )}
        <PlayIconButton isDisabled={isRunning} onRun={onRunCalculation} />
        <PlaySelectedIconButton isDisabled={isRunning} />
        <ErrorIconButton isError={isError} isDisabled={isRunning} onClick={onToggleLog} />
        <Box w="1px" bg="border" h="32px" />

        <SaveIconButton isDisabled={isRunning} lastSaveDate={lastSaveDate} onSave={onSave} />
        <UserIconButton currentUser={currentUser} onSignOut={onSignOut} />
      </Center>
    </Header>
  );
};

export const ProjectMainBar = observer(ProjectMainBarFC);
