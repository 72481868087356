import {IconButton} from '@chakra-ui/react';
import {FC, memo} from 'react';

import {Svg} from '..';

interface IProps {
  onClick: () => void;
  isDisabled?: boolean;
  variant?: 'ghost | ghostTr';
}

const DeleteButtoFC: FC<IProps> = ({onClick, variant = 'ghost', isDisabled}) => {
  return (
    <IconButton
      size="smSquare"
      aria-label=""
      variant={variant}
      icon={<Svg size="s12" name="Cross" />}
      isDisabled={isDisabled}
      onClick={onClick}
    />
  );
};

export const DeleteButton = memo(DeleteButtoFC);
