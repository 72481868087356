import {useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {useParams} from 'react-router';
import {Grid, GridItem} from '@chakra-ui/react';
import {EStructureItem} from '@progress-fe/core';

import {useStore} from 'core/hooks';
import {LogViewer, ProjectLayout} from 'ui-kit';
import {ProjectJsFormConfigContext} from 'core/context/ProjectJsFormConfigContext';

import {ProjectForms, ProjectStructure, ProjectZones} from './components';

const TechProcessSceneFC = () => {
  const {techProcessStore} = useStore();
  const {uiState, logger} = techProcessStore;

  const {id, checkpointId} = useParams();

  useEffect(() => {
    if (!!id && !!checkpointId) {
      techProcessStore.initProject(id, checkpointId).then();
    }
    return () => {
      techProcessStore.uninitialize();
    };
  }, [id, checkpointId, techProcessStore]);

  return (
    <ProjectJsFormConfigContext>
      <ProjectLayout project={techProcessStore} onRunProject={techProcessStore.run}>
        <Grid position="relative" h="var(--height-layout)" gridTemplateColumns={'280px 440px 1fr'}>
          {/* PROJECT ITEMS: SETTINGS, ELEMENTS, MODELS */}
          <GridItem bg="bg" borderRight="1px" borderColor="border">
            <ProjectStructure />
          </GridItem>

          {/* SELECTED PROJECT ITEM */}
          <GridItem
            bg="bg"
            borderRight="1px"
            borderColor="border"
            overflowY={uiState.entityType === EStructureItem.Settings ? 'hidden' : 'auto'}
          >
            <ProjectForms />
          </GridItem>

          {/* SHOW STRUCTURE, CALCULATION OR RESULTS */}
          <GridItem position={'relative'}>
            <ProjectZones />
            {logger.isOpen && <LogViewer logPoints={logger.points} onClose={logger.toggleOpen} />}
          </GridItem>
        </Grid>
      </ProjectLayout>
    </ProjectJsFormConfigContext>
  );
};

export const TechProcessScene = observer(TechProcessSceneFC);

export default TechProcessScene;
