import {FC, memo} from 'react';
import {Button, HStack} from '@chakra-ui/react';

import {TCatalyst} from '../../CatalystsListJsField.types';
import {DeleteButton} from '../../../../helpers';

interface IProps {
  catalyst: TCatalyst;
  isReadonly: boolean;
  onSelect: () => void;
  onDelete: () => void;
}

const CatalystItemFC: FC<IProps> = ({catalyst, isReadonly, onSelect, onDelete}) => {
  return (
    <HStack gap="4px" w="100%">
      <Button
        variant="ghost"
        pointerEvents={isReadonly ? 'none' : 'auto'}
        p="4px"
        justifyContent="left"
        size="smSquare"
        width="100%"
        onClick={onSelect}
      >
        {catalyst.name}
      </Button>
      {!isReadonly && <DeleteButton isDisabled={isReadonly} onClick={onDelete} />}
    </HStack>
  );
};

export const CatalystItem = memo(CatalystItemFC);
