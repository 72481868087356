import {FC, memo} from 'react';
import {Center} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';

interface IProps {
  withDots?: boolean;
  sx?: SystemStyleObject;
}

const FlexDividerFC: FC<IProps> = ({withDots, sx}) => {
  return (
    <Center
      h="8px"
      fontSize="8px"
      lineHeight="8px"
      letterSpacing="2px"
      fontWeight={700}
      borderTop="1px"
      borderBottom="1px"
      borderColor="border"
      sx={sx}
    >
      {withDots && <>•••</>}
    </Center>
  );
};

export const FlexDivider = memo(FlexDividerFC);
