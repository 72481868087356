import {cast, flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {Edge, Node, XYPosition} from '@xyflow/react';
import {EElement, EventEmitter} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';
import {
  IRFNodePort,
  TRFWorkZone,
  TRFEdgeDataConfig,
  TRFWorkZoneDataConfig
} from '@progress-fe/rf-core';

import {CreateElementActionResult, ElementsOut, TechProcessApi, UpdateOut} from 'api';
import {ElementDetails, RequestModel, ResetModel, TElementDetailsModel} from 'core/models';
import {ELEMENTS_LIST} from 'core/mocks/elements.mocks';
import {WORK_ZONE_LIST} from 'core/mocks/workZone.mocks';

const ProjectElements = types
  .compose(
    ResetModel,
    types.model('ProjectElements', {
      projectUuid: '',
      checkpointUuid: '',

      elements: types.optional(types.array(ElementDetails), []),
      nodes: types.optional(types.array(types.frozen<Node<TRFWorkZoneDataConfig>>()), []),
      edges: types.optional(types.array(types.frozen<Edge<TRFEdgeDataConfig>>()), []),
      workzoneLastUpdate: new Date(),

      formDataRequest: types.optional(RequestModel, {}),
      positionRequest: types.optional(RequestModel, {}),
      actionRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    clearJsonSchemas() {
      self.elements.forEach((element) => {
        element.clearJsonSchemas();
      });
    },
    async loadJsonSchemasByUuid(uuid: string) {
      const element = self.elements.find((e) => e.uuid === uuid);
      if (!!element) {
        await element.loadJsonSchemas(self.projectUuid, self.checkpointUuid);
      }
    }
  }))
  .actions((self) => ({
    _loadElements: flow(function* () {
      // TODO: Temp. Removal
      const mockElements = ELEMENTS_LIST.find((m) => m.projectId === self.projectUuid)?.items || [];
      if (mockElements.length) {
        self.elements = cast(mockElements);
        return;
      }

      const response: ElementsOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetElements.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!!response?.elements) {
        self.elements = cast(
          response.elements.map((el) => ({
            uuid: el.uuid,
            name: el.name,
            lastUpdated: new Date(),
            type: el.type as EElement
          }))
        );
      }
    }),
    _loadWorkZone: flow(function* () {
      // TODO: Temp. Removal
      const mockWorkZone = WORK_ZONE_LIST.find((d) => d.projectId === self.projectUuid);
      if (mockWorkZone) {
        self.nodes = cast(mockWorkZone.nodes);
        self.edges = cast(mockWorkZone.edges);
        return;
      }

      const response: unknown = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetWorkZone.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (!!response) {
        const workZone = response as TRFWorkZone;
        self.nodes = cast(workZone.nodes);
        self.edges = cast(workZone.edges);
      }
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.resetModel();
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;

      yield self._loadElements();
      yield self._loadWorkZone();
    })
  }))
  .actions((self) => ({
    add: flow(function* (elementType: EElement, position?: XYPosition) {
      const response: CreateElementActionResult = yield self.actionRequest.send(
        TechProcessApi.techProcessCreateElement.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          idempotencyKey: uuidv4(),
          newElement: {
            type: elementType,
            position: position || {x: 0, y: 0}
          }
        }
      );

      return self.actionRequest.isDone && !!response
        ? ElementDetails.create({
            uuid: response.data.uuid,
            name: response.data.name,
            lastUpdated: new Date(),
            type: elementType
          })
        : null;
    }),
    remove: flow(function* (uuid: string) {
      yield self.actionRequest.send(TechProcessApi.techProcessDeleteElement.bind(TechProcessApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        elementUuid: uuid
      });

      return self.actionRequest.isDone;
    }),
    connect: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      yield self.actionRequest.send(TechProcessApi.techProcessAddConnection.bind(TechProcessApi), {
        projectUuid: self.projectUuid,
        checkpointUuid: self.checkpointUuid,
        bodyTechProcessAddConnection: {
          sourceInfo: {
            elementUuid: from.nodeUuid,
            portCode: from.portCode,
            portNumber: null
          },
          targetInfo: {
            elementUuid: to.nodeUuid,
            portCode: to.portCode,
            portNumber: null
          }
        }
      });

      return self.actionRequest.isDone;
    }),
    disconnect: flow(function* (from: IRFNodePort, to: IRFNodePort) {
      yield self.actionRequest.send(
        TechProcessApi.techProcessRemoveConnection.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          bodyTechProcessRemoveConnection: {
            sourceInfo: {
              elementUuid: from.nodeUuid,
              portCode: from.portCode,
              portNumber: null
            },
            targetInfo: {
              elementUuid: to.nodeUuid,
              portCode: to.portCode,
              portNumber: null
            }
          }
        }
      );

      return self.actionRequest.isDone;
    })
  }))
  .actions((self) => ({
    _updateElementName(uuid: string, name: string) {
      EventEmitter.emit('ElementNameChanged', uuid, name);

      const element = self.elements.find((n) => n.uuid === uuid);
      element?.setName(name);

      const node = self.nodes.find((n) => n.id === uuid);
      if (!!node) {
        const changedNode = {...node, data: {...node.data, elementName: name}};
        const filteredNodes = self.nodes.filter((n) => n.id !== uuid);

        self.nodes = cast([...filteredNodes, changedNode]);
      }
    }
  }))
  .actions((self) => ({
    updateElementFormData: flow(function* (uuid: string, jsonSchemaId: string, formData: unknown) {
      const element = self.elements.find((el) => el.uuid === uuid);
      const jsonSchema = element?.jsonSchemas.find((js) => js.id === jsonSchemaId);

      if (!!element && !!jsonSchema) {
        jsonSchema.updateFormData(formData);

        const response: UpdateOut = yield self.formDataRequest.send(
          TechProcessApi.techProcessUpdateElementInstance.bind(TechProcessApi),
          {
            elementUuid: uuid,
            body: formData as object,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid
          }
        );

        if (self.formDataRequest.isDone) {
          if (!!response.name) {
            console.info('Element name was changed.');
            self._updateElementName(uuid, response.name);
          }
          if (!!response.schemas) {
            console.info('Element schemas was changed.');
            element.setJsonSchemas(response.schemas);
          }
          if (!!response.workzone) {
            console.info(`Work zone was changed. Nodes: ${response.workzone.nodes.length}.`);
            console.info(`Work zone was changed. Edges: ${response.workzone.edges.length}.`);
            self.nodes = cast((response.workzone as TRFWorkZone).nodes);
            self.edges = cast((response.workzone as TRFWorkZone).edges);
            self.workzoneLastUpdate = new Date();
          }
        }
      }
    }),
    updateNodePosition: flow(function* (uuid: string, position: XYPosition) {
      const node = self.nodes.find((n) => n.id === uuid);
      if (!!node) {
        self.nodes = cast([...self.nodes.filter((n) => n.id !== uuid), {...node, position}]);

        yield self.positionRequest.send(
          TechProcessApi.techProcessUpdateElementInstancePosition.bind(TechProcessApi),
          {
            elementUuid: uuid,
            projectUuid: self.projectUuid,
            checkpointUuid: self.checkpointUuid,
            position: position
          }
        );
      }
    })
  }))
  .actions((self) => ({
    hasElement(elementId: string): boolean {
      return self.elements.some((e) => e.uuid === elementId);
    },
    findElement(elementId: string): TElementDetailsModel | undefined {
      return self.elements.find((e) => e.uuid === elementId);
    },
    resetCalculatedFieldsOfElements(): void {
      self.elements.forEach((element) => {
        element.jsonSchemas.forEach((jsonSchema) => {
          jsonSchema.resetFormFields();
        });
      });
    }
  }))
  .views((self) => ({
    get nodeList() {
      return getSnapshot(self.nodes);
    },
    get edgeList() {
      return getSnapshot(self.edges);
    }
  }))
  .views((self) => ({
    get isLoading(): boolean {
      return self.fetchRequest.isPending;
    },
    get isFormDataUpdating(): boolean {
      return self.formDataRequest.isPending;
    }
  }));

export type TProjectElementsModel = Instance<typeof ProjectElements>;

export {ProjectElements};
