/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ElementTemplate} from './ElementTemplate';
import {
  ElementTemplateFromJSON,
  ElementTemplateFromJSONTyped,
  ElementTemplateToJSON
} from './ElementTemplate';
import type {ElementClassName} from './ElementClassName';
import {
  ElementClassNameFromJSON,
  ElementClassNameFromJSONTyped,
  ElementClassNameToJSON
} from './ElementClassName';

/**
 *
 * @export
 * @interface AppCatalogElementsSchemasElement
 */
export interface AppCatalogElementsSchemasElement {
  /**
   *
   * @type {ElementClassName}
   * @memberof AppCatalogElementsSchemasElement
   */
  type: ElementClassName;
  /**
   *
   * @type {string}
   * @memberof AppCatalogElementsSchemasElement
   */
  name: string;
  /**
   *
   * @type {Array<ElementTemplate>}
   * @memberof AppCatalogElementsSchemasElement
   */
  templates: Array<ElementTemplate>;
  /**
   *
   * @type {boolean}
   * @memberof AppCatalogElementsSchemasElement
   */
  isDisabled: boolean;
}

/**
 * Check if a given object implements the AppCatalogElementsSchemasElement interface.
 */
export function instanceOfAppCatalogElementsSchemasElement(
  value: object
): value is AppCatalogElementsSchemasElement {
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('templates' in value) || value['templates'] === undefined) return false;
  if (!('isDisabled' in value) || value['isDisabled'] === undefined) return false;
  return true;
}

export function AppCatalogElementsSchemasElementFromJSON(
  json: any
): AppCatalogElementsSchemasElement {
  return AppCatalogElementsSchemasElementFromJSONTyped(json, false);
}

export function AppCatalogElementsSchemasElementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppCatalogElementsSchemasElement {
  if (json == null) {
    return json;
  }
  return {
    type: ElementClassNameFromJSON(json['type']),
    name: json['name'],
    templates: (json['templates'] as Array<any>).map(ElementTemplateFromJSON),
    isDisabled: json['is_disabled']
  };
}

export function AppCatalogElementsSchemasElementToJSON(
  value?: AppCatalogElementsSchemasElement | null
): any {
  if (value == null) {
    return value;
  }
  return {
    type: ElementClassNameToJSON(value['type']),
    name: value['name'],
    templates: (value['templates'] as Array<any>).map(ElementTemplateToJSON),
    is_disabled: value['isDisabled']
  };
}
