import {FC, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Grid, IconButton} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {EComponentsFamilyClass, IComponent} from '@progress-fe/core';

import {Svg} from '../../../../helpers';
import {VirtualTable} from '../../../../tables';
import {Input, Select} from '../../../../inputs';
import {ISelectOption, ISimpleTableColumn, ISimpleTableRow} from '../../../../../interfaces';

interface IProps {
  favorites: Set<string>;
  components: IComponent[];
  selectedComponents: Set<string>;
  onAddComponent: (value: string) => void;
  onDeleteComponent: (value: string) => void;
  onToggleFavorite?: (componentId: string) => void;
  onAddAllComponents?: () => void;
  onDeleteAllComponents?: () => void;
  tableSx?: SystemStyleObject;
}

const PureComponentsFC: FC<IProps> = ({
  favorites,
  components,
  selectedComponents,
  onAddComponent,
  onDeleteComponent,
  onAddAllComponents,
  onDeleteAllComponents,
  onToggleFavorite,
  tableSx
}) => {
  const [query, setQuery] = useState('');
  const [selectedFamilyType, setSelectedFamilyType] = useState(EComponentsFamilyClass.ALL);

  const pureChemicalFamilyTypeOptions: ISelectOption<EComponentsFamilyClass>[] = [
    {value: EComponentsFamilyClass.ALL, label: 'Все классы'},
    {value: EComponentsFamilyClass.HYDROCARBONS, label: 'Углеводороды'},
    {value: EComponentsFamilyClass.HALOGENS, label: 'Галогены'},
    {value: EComponentsFamilyClass.ALCOHOLS, label: 'Спирты'},
    {value: EComponentsFamilyClass.ALDEHYDES, label: 'Альдегиды'},
    {value: EComponentsFamilyClass.HYDROCARBON_ACIDS, label: 'Углевод. кистоты'},
    {value: EComponentsFamilyClass.KETONES, label: 'Кетоны'},
    {value: EComponentsFamilyClass.NON_ORGANIC, label: 'Неогранические'},
    {value: EComponentsFamilyClass.PHENOLS, label: 'Фенолы'},
    {value: EComponentsFamilyClass.SULPHUROUS, label: 'Серосодержащие'},
    {value: EComponentsFamilyClass.AMINES, label: 'Амины'},
    {value: EComponentsFamilyClass.COMPOUND_ETHER, label: 'Cложные эфиры'},
    {value: EComponentsFamilyClass.SIMPLE_ETHER, label: 'Простые эфиры'},
    {value: EComponentsFamilyClass.NITRILES, label: 'Нитрилы'},
    {value: EComponentsFamilyClass.OTHERS, label: 'Прочие'}
  ];

  const columns: ISimpleTableColumn[] = [
    {name: 'Название', align: 'left'},
    {name: 'Формула', align: 'left'},
    {name: 'Структура', align: 'left'},
    {
      name: '',
      width: '24px',
      columnTemplate: '24px',
      render: (id: string) => {
        const isFavorite = favorites.has(id);
        return (
          onToggleFavorite && (
            <IconButton
              size="2sm"
              aria-label=""
              variant="transparent"
              icon={<Svg size="s12" name={isFavorite ? 'FavoriteOn' : 'Favorite'} />}
              onClick={() => onToggleFavorite(id)}
              opacity={isFavorite ? 1 : 0}
              _groupHover={{opacity: !isFavorite ? 1 : undefined}}
            />
          )
        );
      }
    }
  ];

  const filteredOptionsByName = components.filter(
    (opt) =>
      opt.name.toLowerCase().includes(query.toLowerCase()) ||
      opt.formula?.toLowerCase().includes(query.toLowerCase())
  );

  const filteredOptionsByNameAndClass = filteredOptionsByName.filter(
    (opt) => selectedFamilyType === EComponentsFamilyClass.ALL || selectedFamilyType === opt.family
  );

  const rows: ISimpleTableRow<string>[] = filteredOptionsByNameAndClass.map((opt) => ({
    key: opt.uuid,
    values: [opt.name, opt.formula || '', opt.structure || '', opt.uuid],
    isSelected: selectedComponents.has(opt.uuid),
    isDisabled: false
  }));

  return (
    <Grid templateRows="auto 1fr" gap="8px">
      <Grid gridTemplateColumns="1fr 140px" gap="8px">
        <Input
          name="search"
          size="xs"
          variant="outline"
          value={query}
          placeholder="Поиск"
          onChange={setQuery}
        />
        <Select
          name="chemical_class"
          options={pureChemicalFamilyTypeOptions}
          value={pureChemicalFamilyTypeOptions.find((opt) => opt.value === selectedFamilyType)}
          onChange={(option) =>
            setSelectedFamilyType((option as ISelectOption<EComponentsFamilyClass>).value)
          }
        />
      </Grid>

      <VirtualTable
        rows={rows}
        isCheckboxShown
        columns={columns}
        noItemsTitle="Компоненты не найдены"
        tableSx={tableSx}
        onSelectAll={onAddAllComponents}
        onUnselectAll={onDeleteAllComponents}
        onSelect={(value: string, isSelected: boolean) => {
          if (isSelected) {
            onAddComponent(value);
          } else {
            onDeleteComponent(value);
          }
        }}
      />
    </Grid>
  );
};

export const PureComponents = observer(PureComponentsFC);
