import {memo, useContext, useMemo, useState} from 'react';
import {FieldProps} from '@rjsf/utils';
import {isClose} from 'is-close';
import _ from 'lodash-es';
import {hasValue, EComponentBaseType, useT} from '@progress-fe/core';
import {Box, Button, Center, Flex, Grid, GridItem, IconButton} from '@chakra-ui/react';

import {Dialog, Svg} from '../../helpers';
import {ComponentsLists, ITabOption} from '../../lists';
import {Input, InputNumber, Select} from '../../inputs';
import {ISelectOption} from '../../../interfaces';
import {JsFieldName} from '../../jsFormBase';
import {JsFormConfigContext} from '../../../contexts';

import {TLightFractionFormData, TLightFractionValue} from './LightFractionCompositionJsField.types';

interface IProps extends FieldProps {
  formData: TLightFractionFormData;
}

/**
 * Component renders "/schemas/jsf-light-fraction-composition" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is an instance of TFormData.
 */
const LightFractionCompositionJsFieldFC = ({
  name,
  formData,
  rawErrors,
  schema,
  onChange
}: IProps) => {
  const {t} = useT();

  const {components} = useContext(JsFormConfigContext);

  const {pureList = []} = components || {};

  const [isDictionaryShown, setIsDictionaryShown] = useState(false);

  const keys = useMemo(
    () => new Set(Object.keys(formData.fieldValues.value)),
    [formData.fieldValues.value]
  );

  const pureListSelected = pureList.filter((c) => keys.has(c.uuid));

  const hasComponents = pureListSelected.length > 0;
  const selectedComponentsAmount = pureListSelected.length;
  const isFieldInvalid = !!rawErrors || selectedComponentsAmount !== keys.size;
  const isComponentColumnVisible = formData.fieldConfig.isManual;

  const gridTemplate = isComponentColumnVisible ? '126px 1fr 1fr 24px' : '1fr 1fr 24px';

  const selectedOption = useMemo(() => {
    return formData.fieldConfig.options.find(
      (opt) => opt.value === formData.fieldValues.optionValue
    );
  }, [formData.fieldConfig.options, formData.fieldValues.optionValue]);

  // TODO: Sum of components must be calculated on BE side
  const sumOfComponents = useMemo(() => {
    const valuesArray = Object.values(formData.fieldValues.value).map((val) => val?.quantity);
    if (valuesArray.every((v) => v === null)) {
      return undefined;
    }

    const sum = _.sum(valuesArray.map((i) => (hasValue(i) ? Number(i) : undefined)));
    const isCloseToOne = isClose(1.0, sum, 0.0000000001);
    return sum === 1 || isCloseToOne ? 1 : sum;
  }, [formData.fieldValues.value]);

  const handleChangeFormOptionValue = (option: ISelectOption<string | number>) => {
    onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        optionValue: option.value
      }
    });
  };

  const handleChangeFormValue = (value: TLightFractionValue) => {
    onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        value
      }
    });
  };

  const handleAddComponent = (key: string) => {
    handleChangeFormValue({
      ...formData.fieldValues.value,
      [key]: null
    });
  };

  const handleChangeValue = (key: string, value: string | null) => {
    const currentValue = formData.fieldValues.value[key] || {temperature: ''};
    handleChangeFormValue({
      ...formData.fieldValues.value,
      [key]: {...currentValue, quantity: value}
    });
  };

  const handleDeleteComponent = (key: string) => {
    handleChangeFormValue({..._.omit(formData.fieldValues.value, [key])});
  };

  const tabs: ITabOption[] = useMemo(() => {
    return [
      {
        type: EComponentBaseType.PURE,
        label: t(`enum.componentBaseType.${EComponentBaseType.PURE}`),
        components: pureList
      }
    ];
  }, [pureList, t]);

  return (
    <>
      <Box data-testid="FlowCompositionJsField-test">
        <Flex p="4px 0" flexDirection="column" gap="8px">
          {/* SELECT FIELD */}
          <Grid gridTemplateColumns={`minmax(0, 130px) 1fr`} alignItems={'center'}>
            <JsFieldName name={formData.fieldConfig.title} />
            <Select
              name={name}
              options={formData.fieldConfig.options}
              placeholder={t('common.notSelected')}
              value={formData.fieldConfig.options.find(
                (opt) => opt.value === formData.fieldValues.optionValue
              )}
              onChange={(newValue) => {
                handleChangeFormOptionValue(newValue as ISelectOption<string | number>);
              }}
            />
          </Grid>

          {/* CHEMICAL COMPONENTS LIST */}
          {hasComponents ? (
            <GridItem
              p="4px"
              borderRadius="4px"
              border="1px solid"
              borderColor={isFieldInvalid ? 'error' : 'border'}
            >
              {/* CHEMICAL COMPONENTS LIST HEADER */}
              <Grid
                gap="4px"
                p="0 0 4px 0"
                alignItems="center"
                borderBottom="1px solid"
                borderColor="border"
                templateColumns={gridTemplate}
              >
                {isComponentColumnVisible && <GridItem>{t('common.component')}</GridItem>}
                <GridItem>{t('common.quantity')}</GridItem>
                <GridItem>{t('common.boilingPoint')}</GridItem>
                <GridItem />
              </Grid>

              {/* CHEMICAL COMPONENTS LIST ITEMS */}
              <Box p="2px 0">
                {pureListSelected.map((item) => (
                  <Grid key={item.uuid} gap="4px" p="2px 0" templateColumns={gridTemplate}>
                    {isComponentColumnVisible && <Flex align="center">{item.name}</Flex>}
                    <InputNumber
                      isFloat
                      min={0}
                      size="xs"
                      variant="outline"
                      isOnChangeOnlyOnBlur
                      disabled={schema.readOnly}
                      rightElement={selectedOption?.unit}
                      value={formData.fieldValues.value[item.uuid]?.quantity || undefined}
                      onChange={(value) => handleChangeValue(item.uuid, value)}
                    />
                    <InputNumber
                      isFloat
                      min={0}
                      size="xs"
                      variant="outline"
                      disabled={true}
                      rightElement={schema.$comment}
                      value={formData.fieldValues.value[item.uuid]?.temperature || undefined}
                    />
                    <IconButton
                      size="smSquare"
                      aria-label=""
                      variant="ghost"
                      isDisabled={schema.readOnly}
                      icon={<Svg size="s12" name="Cross" />}
                      onClick={() => handleDeleteComponent(item.uuid)}
                    />
                  </Grid>
                ))}
              </Box>

              {/* CHEMICAL COMPONENTS SUMMARY */}
              <Grid
                gap="4px"
                p="4px 0 0 0"
                borderTop="1px solid"
                borderColor="border"
                templateColumns="126px 1fr auto"
              >
                <Flex align="center">{t('common.total')}</Flex>
                <Input
                  size="xs"
                  isDisabled
                  variant="outline"
                  value={sumOfComponents}
                  isInvalid={sumOfComponents !== 1}
                />
              </Grid>
            </GridItem>
          ) : (
            <Center
              h="32px"
              color="bodyText"
              border="1px solid"
              borderColor={isFieldInvalid ? 'error' : 'border'}
              borderRadius="4px"
            >
              <Box opacity="0.6">{t('common.noComponents')}</Box>
            </Center>
          )}

          {/* BuTTON FOR ADDING CHEMICAL COMPONENTS */}
          {!schema.readOnly && (
            <Button
              variant="ghost"
              size="smSquare"
              w="100%"
              onClick={() => setIsDictionaryShown(true)}
            >
              {t('actions.add')}
            </Button>
          )}
        </Flex>
      </Box>

      {/* CHEMICAL COMPONENTS DICTIONARY */}
      {isDictionaryShown && (
        <Dialog title={t('common.addComponent')} onClose={() => setIsDictionaryShown(false)}>
          <ComponentsLists
            tableSx={{height: 300}}
            title={''}
            tabs={tabs}
            selectedComponentsIds={Array.from(keys.values())}
            onAddComponent={handleAddComponent}
            onDeleteComponent={handleDeleteComponent}
            onClose={() => setIsDictionaryShown(false)}
          />
        </Dialog>
      )}
    </>
  );
};

export const LightFractionCompositionJsField = memo(LightFractionCompositionJsFieldFC);
