/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {CalculationErrorInfo} from './CalculationErrorInfo';
import {
  CalculationErrorInfoFromJSON,
  CalculationErrorInfoFromJSONTyped,
  CalculationErrorInfoToJSON
} from './CalculationErrorInfo';

/**
 *
 * @export
 * @interface CalculationTaskRunStatusErrorInfoInner
 */
export interface CalculationTaskRunStatusErrorInfoInner {
  /**
   *
   * @type {string}
   * @memberof CalculationTaskRunStatusErrorInfoInner
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof CalculationTaskRunStatusErrorInfoInner
   */
  elementUuid?: string;
  /**
   *
   * @type {string}
   * @memberof CalculationTaskRunStatusErrorInfoInner
   */
  tabKey?: string;
  /**
   *
   * @type {string}
   * @memberof CalculationTaskRunStatusErrorInfoInner
   */
  fieldName?: string;
}

/**
 * Check if a given object implements the CalculationTaskRunStatusErrorInfoInner interface.
 */
export function instanceOfCalculationTaskRunStatusErrorInfoInner(
  value: object
): value is CalculationTaskRunStatusErrorInfoInner {
  return true;
}

export function CalculationTaskRunStatusErrorInfoInnerFromJSON(
  json: any
): CalculationTaskRunStatusErrorInfoInner {
  return CalculationTaskRunStatusErrorInfoInnerFromJSONTyped(json, false);
}

export function CalculationTaskRunStatusErrorInfoInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CalculationTaskRunStatusErrorInfoInner {
  if (json == null) {
    return json;
  }
  return {
    reason: json['reason'] == null ? undefined : json['reason'],
    elementUuid: json['element_uuid'] == null ? undefined : json['element_uuid'],
    tabKey: json['tab_key'] == null ? undefined : json['tab_key'],
    fieldName: json['field_name'] == null ? undefined : json['field_name']
  };
}

export function CalculationTaskRunStatusErrorInfoInnerToJSON(
  value?: CalculationTaskRunStatusErrorInfoInner | null
): any {
  if (value == null) {
    return value;
  }
  return {
    reason: value['reason'],
    element_uuid: value['elementUuid'],
    tab_key: value['tabKey'],
    field_name: value['fieldName']
  };
}
