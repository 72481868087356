import {useContext, useEffect, useMemo, useState} from 'react';
import {FieldProps} from '@rjsf/utils';
import _ from 'lodash-es';
import {useT, hasValue, IComponent, EComponentBaseType} from '@progress-fe/core';
import {
  Box,
  Center,
  Menu,
  Flex,
  Grid,
  Button,
  GridItem,
  IconButton,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList
} from '@chakra-ui/react';
import {observer} from 'mobx-react-lite';

import {Dialog, Svg} from '../../helpers';
import {ComponentsLists, ITabOption} from '../../lists';
import {Input, InputNumber, Select} from '../../inputs';
import {useJsFieldFormData} from '../../../hooks';
import {ISelectOption} from '../../../interfaces';
import {JsFormConfigContext} from '../../../contexts';
import {JsFieldName, JsFieldError} from '../../jsFormBase';

type TFormDataOption = ISelectOption<string | number> & {unit?: string};

type TFormDataConfig = {
  title: string;
  normalizable: boolean;
  callbackCode: string | null;
  options: TFormDataOption[];
  tolerance?: {
    unit: string;
  };
};

type TValue = {value: string | null; tolerance?: string | null};

type TFormDataFieldValue = {
  optionValue: string | number;
  value: Record<string, TValue>;
};

type TFormData = {
  fieldConfig: TFormDataConfig;
  fieldValues: TFormDataFieldValue;
};

/**
 * Component renders "/schemas/jsf-flow-composition" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is an instance of TFormData.
 */
const FlowCompositionInitApproxJsFieldFC = (props: FieldProps) => {
  const [isDialog, setIsDialog] = useState(false);
  const {components, addToFavorites, removeFromFavorites, runElementCallback} =
    useContext(JsFormConfigContext) || {};
  const {favorites = [], pureList = [], dictionary = {}} = components || {};

  const {t} = useT();

  const formData = useMemo(
    () => (hasValue(props.formData) ? (props.formData as TFormData) : null),
    [props.formData]
  );

  const {fieldConfig, fieldValues, onChangeFieldValues, onChangeFieldConfig} = useJsFieldFormData({
    config: formData?.fieldConfig || null,
    values: formData?.fieldValues || null,
    onChange: props.onChange
  });

  useEffect(() => {
    onChangeFieldConfig(formData?.fieldConfig);
  }, [formData?.fieldConfig, onChangeFieldConfig]);

  const selectedComponentsIds: string[] = useMemo(
    () => (!!fieldValues ? Object.keys(fieldValues.value) : []),
    [fieldValues]
  );

  const selectedOption = useMemo(() => {
    return fieldConfig?.options.find((opt) => opt.value === fieldValues?.optionValue);
  }, [fieldConfig, fieldValues]);

  const hasComponents = !!selectedComponentsIds.length;
  const selectedComponentsAmount = selectedComponentsIds.length;
  const isFieldInvalid =
    !!props.rawErrors || selectedComponentsAmount !== selectedComponentsIds.length;

  const sumOfComponents = useMemo(() => {
    if (!fieldValues) {
      return undefined;
    }
    const valuesArray = Object.values(fieldValues.value).map((item) => item.value);

    if (valuesArray.every((v) => v === null)) {
      return undefined;
    }

    return _.sum(valuesArray.map((i) => (hasValue(i) ? Number(i) : undefined)));
  }, [fieldValues]);

  const onChangeFormOptionValue = (option: ISelectOption<string | number>) => {
    if (!!fieldValues) {
      onChangeFieldValues({...fieldValues, optionValue: option.value});
    }
  };

  const onChangeFormValue = (value: Record<string, TValue>) => {
    if (!!fieldValues) {
      onChangeFieldValues({...fieldValues, value: value});
    }
  };

  const handleAddComponent = (key: string) => {
    if (!fieldValues) return;
    const initValue: TValue = {value: null};

    if (fieldConfig?.tolerance) {
      initValue.tolerance = null;
    }

    onChangeFormValue({...fieldValues.value, [key]: initValue});
  };

  const handleChangeValue = (key: string, value: string | null, property: keyof TValue) => {
    if (!fieldValues) return;
    onChangeFormValue({
      ...fieldValues.value,
      [key]: {...fieldValues.value[key], [property]: value}
    });
  };

  const handleDeleteComponent = (key: string) => {
    if (!fieldValues) return;
    onChangeFormValue({..._.omit(fieldValues.value, [key])});
  };

  const handleClearValues = () => {
    if (!fieldValues) return;
    const existingKeys = Object.keys(fieldValues.value);

    const resetedValue: TValue = {value: null};

    if (fieldConfig?.tolerance) {
      resetedValue.tolerance = null;
    }

    let newValue = {};
    existingKeys.forEach((key) => {
      newValue = {...newValue, [key]: resetedValue};
    });

    onChangeFormValue(newValue);
  };

  const handleNormalizeCallback = async () => {
    const formDataResult = await runElementCallback?.(fieldConfig?.callbackCode || '');
    if (!!hasValue(formDataResult)) {
      onChangeFieldConfig((formDataResult as TFormData).fieldConfig);
      onChangeFieldValues((formDataResult as TFormData).fieldValues);
    }
  };

  const tabs: ITabOption[] = useMemo(() => {
    return [
      {
        type: null,
        label: t(`common.favorites`),
        components: favorites
      },
      {
        type: EComponentBaseType.PURE,
        label: t(`enum.componentBaseType.${EComponentBaseType.PURE}`),
        components: pureList
      },
      {
        type: EComponentBaseType.HYPOTHETICAL,
        label: t(`enum.componentBaseType.${EComponentBaseType.HYPOTHETICAL}`),
        components: []
      },
      {
        type: EComponentBaseType.PSEUDO,
        label: t(`enum.componentBaseType.${EComponentBaseType.PSEUDO}`),
        components: []
      }
    ];
  }, [favorites, pureList, t]);

  if (!fieldConfig || !fieldValues) {
    return <JsFieldError propName={props.name} />;
  }

  const gridTemplate = fieldConfig.tolerance ? '176px 1fr 1fr 24px' : '176px 1fr 24px';

  return (
    <Box data-testid="FlowCompositionInitApproxJsField-test">
      <Flex p="4px 0" flexDirection="column" gap="8px">
        {/* SELECT FIELD */}
        <Grid gridTemplateColumns={`minmax(0, 184px) 1fr`} alignItems={'center'}>
          <JsFieldName name={fieldConfig.title} />
          <Select
            name={props.name}
            value={selectedOption}
            options={fieldConfig.options}
            placeholder={t('common.notSelected')}
            onChange={(newValue) => {
              onChangeFormOptionValue(newValue as ISelectOption<string | number>);
            }}
          />
        </Grid>

        {/* CHEMICAL COMPONENTS LIST */}
        {hasComponents ? (
          <GridItem
            p="4px"
            borderRadius="4px"
            border="1px solid"
            borderColor={isFieldInvalid ? 'error' : 'border'}
          >
            {/* CHEMICAL COMPONENTS LIST HEADER */}
            <Grid
              gap="4px"
              p="0 0 4px 0"
              alignItems="center"
              borderBottom="1px solid"
              borderColor="border"
              templateColumns={gridTemplate}
            >
              <GridItem>{t('fields.component')}</GridItem>
              <GridItem>{t('fields.amount')}</GridItem>
              {fieldConfig.tolerance && <GridItem>{t('fields.tolerance')}</GridItem>}
              <GridItem>
                <IconButton
                  size="smSquare"
                  aria-label=""
                  variant="ghostTr"
                  icon={<Svg size="s12" name="Cross" />}
                  isDisabled={props.schema.readOnly}
                  onClick={() => handleClearValues()}
                />
              </GridItem>
            </Grid>

            {/* CHEMICAL COMPONENTS LIST ITEMS */}
            <Box p="2px 0">
              {selectedComponentsIds.map((uuid: string) => (
                <Grid key={uuid} gap="4px" p="2px 0" templateColumns={gridTemplate}>
                  <Flex align="center">{dictionary[uuid]?.name}</Flex>
                  <InputNumber
                    isFloat
                    min={0}
                    size="xs"
                    variant="outline"
                    sx={{width: '100%'}}
                    isOnChangeOnlyOnBlur
                    disabled={props.schema.readOnly}
                    rightElement={selectedOption?.unit}
                    isInvalid={!hasValue(fieldValues.value[uuid]?.value)}
                    value={fieldValues.value[uuid]?.value || undefined}
                    onChange={(value) => handleChangeValue(uuid, value, 'value')}
                  />
                  {fieldConfig.tolerance && (
                    <InputNumber
                      isFloat
                      min={0}
                      size="xs"
                      variant="outline"
                      sx={{width: '100%'}}
                      isOnChangeOnlyOnBlur
                      disabled={props.schema.readOnly}
                      rightElement={fieldConfig.tolerance.unit}
                      isInvalid={!hasValue(fieldValues.value[uuid]?.tolerance)}
                      value={fieldValues.value[uuid]?.tolerance || undefined}
                      onChange={(value) => handleChangeValue(uuid, value, 'tolerance')}
                    />
                  )}
                  <IconButton
                    size="smSquare"
                    aria-label=""
                    variant="ghost"
                    isDisabled={props.schema.readOnly}
                    icon={<Svg size="s12" name="Cross" />}
                    onClick={() => handleDeleteComponent(uuid)}
                  />
                </Grid>
              ))}
            </Box>

            {/* CHEMICAL COMPONENTS SUMMARY */}
            <Grid
              gap="4px"
              p="4px 0 0 0"
              borderTop="1px solid"
              borderColor="border"
              templateColumns="176px 1fr auto"
            >
              <Flex align="center">{t('common.total')}</Flex>
              <Input
                size="xs"
                isDisabled
                variant="outline"
                value={sumOfComponents}
                isInvalid={fieldConfig.normalizable && sumOfComponents !== 1}
              />
              {fieldConfig.normalizable && (
                <Button
                  size="xs"
                  variant="ghost"
                  isDisabled={props.schema.readOnly}
                  onClick={handleNormalizeCallback}
                >
                  {t('actions.normalize')}
                </Button>
              )}
            </Grid>
          </GridItem>
        ) : (
          <Center
            h="32px"
            color="bodyText"
            border="1px solid"
            borderColor={isFieldInvalid ? 'error' : 'border'}
            borderRadius="4px"
          >
            <Box opacity="0.6">{t('components.no')}</Box>
          </Center>
        )}

        {/* MENU FOR ADDING CHEMICAL COMPONENTS */}
        {!props.schema.readOnly && (
          <GridItem>
            <Menu matchWidth offset={[0, 2]} variant="outline">
              <MenuButton
                size="xs"
                as={Button}
                width="100%"
                variant="ghost"
                onClick={() => setIsDialog(false)}
              >
                {t('actions.add')}
              </MenuButton>

              {/* DEFAULT CHEMICAL COMPONENTS */}
              <MenuList motionProps={{animate: false}}>
                {favorites.map((item: IComponent) => (
                  <MenuItem
                    key={item.uuid}
                    isDisabled={!!fieldValues.value[item.uuid]}
                    onClick={() => handleAddComponent(item.uuid)}
                  >
                    {item.name}
                  </MenuItem>
                ))}

                {favorites.length === 0 && (
                  <MenuItem isDisabled>{t('components.noFavorite')}</MenuItem>
                )}

                <MenuDivider />
                <MenuItem onClick={() => setIsDialog(true)}>{t('components.all')}</MenuItem>
              </MenuList>
            </Menu>
          </GridItem>
        )}
      </Flex>

      {isDialog && (
        <Dialog title={t('components.add')} onClose={() => setIsDialog(false)}>
          <GridItem pt="8px">
            <ComponentsLists
              tabs={tabs}
              tableSx={{height: 500}}
              selectedComponentsIds={Object.keys(fieldValues.value)}
              onAddComponent={handleAddComponent}
              onDeleteComponent={handleDeleteComponent}
              onAddToFavorite={(item) => addToFavorites?.([item])}
              onDeleteFromFavorite={(item) => removeFromFavorites?.([item])}
              onClose={() => setIsDialog(false)}
            />
          </GridItem>
        </Dialog>
      )}
    </Box>
  );
};

export const FlowCompositionInitApproxJsField = observer(FlowCompositionInitApproxJsFieldFC);
