import {useCallback, useMemo} from 'react';
import {hasValue} from '@progress-fe/core';

interface IProps {
  minValue?: string | number;
  maxValue?: string | number;
  exclusiveMinValue?: string | number;
  exclusiveMaxValue?: string | number;
}

const useMinMax = ({minValue, maxValue, exclusiveMinValue, exclusiveMaxValue}: IProps) => {
  const min = useMemo(() => (hasValue(minValue) ? Number(minValue) : undefined), [minValue]);
  const max = useMemo(() => (hasValue(maxValue) ? Number(maxValue) : undefined), [maxValue]);

  const exclusiveMin = useMemo(
    () => (hasValue(exclusiveMinValue) ? Number(exclusiveMinValue) : undefined),
    [exclusiveMinValue]
  );

  const exclusiveMax = useMemo(
    () => (hasValue(exclusiveMaxValue) ? Number(exclusiveMaxValue) : undefined),
    [exclusiveMaxValue]
  );

  const isNegativeAllowed = useMemo(() => {
    if (hasValue(min)) return min < 0;
    if (hasValue(max)) return max < 0;
    if (hasValue(exclusiveMin)) return exclusiveMin < 0;
    if (hasValue(exclusiveMax)) return exclusiveMax < 0;
    return true;
  }, [exclusiveMax, exclusiveMin, max, min]);

  const isMinValid = useCallback(
    (value: string | number) => {
      if (hasValue(min) && hasValue(value)) {
        return Number(value) >= min;
      }
      if (hasValue(exclusiveMin) && hasValue(value)) {
        return Number(value) > exclusiveMin;
      }
      return true;
    },
    [exclusiveMin, min]
  );

  const isMaxValid = useCallback(
    (value: string | number) => {
      if (hasValue(max) && hasValue(value)) {
        return Number(value) <= max;
      }
      if (hasValue(exclusiveMax) && hasValue(value)) {
        return Number(value) < exclusiveMax;
      }
      return true;
    },
    [exclusiveMax, max]
  );

  const isMinMaxValid = useCallback(
    (value: string | number) => {
      return isMinValid(value) && isMaxValid(value);
    },
    [isMaxValid, isMinValid]
  );

  return {isNegativeAllowed, isMinValid, isMaxValid, isMinMaxValid};
};

export {useMinMax};
