import {FC, memo, useContext, useMemo, useState} from 'react';
import {Button, Center, Grid, HStack, VStack} from '@chakra-ui/react';
import {FieldProps} from '@rjsf/utils';
import {EComponentBaseType, useT} from '@progress-fe/core';
import _ from 'lodash-es';

import {Dialog} from '../../helpers';
import {ComponentsLists, ITabOption} from '../../lists';
import {JsFieldName} from '../../jsFormBase';
import {InputNumber} from '../../inputs';
import {JsFormConfigContext} from '../../../contexts';

import {
  TReactionCompositinItems,
  TReactionCompositionFormData
} from './ReactionsParametersTableJsField.types';
import {ReactionCompositionTable} from './components';

interface IProps extends FieldProps {
  formData: TReactionCompositionFormData;
}

const ReactionCompositionJsFieldFC: FC<IProps> = ({schema, formData, onChange}) => {
  const {t} = useT();

  // TODO: runElementCallback should be runSomething callback
  const {components, runElementCallback, addToFavorites, removeFromFavorites} =
    useContext(JsFormConfigContext) || {};

  const {favorites = [], pureList = [], dictionary = {}} = components || {};

  const [isDialog, setIsDialog] = useState(false);

  const {fieldValues, fieldConfig} = formData;
  const {items} = fieldValues;

  const existedComponentsIds = Object.keys(items);

  const selectedComponents = useMemo(
    () =>
      Object.keys(formData.fieldValues.items)
        .map((uuid) => dictionary[uuid])
        .filter(Boolean),
    [dictionary, formData.fieldValues.items]
  );

  const handleToggleDialog = () => {
    setIsDialog((prev) => !prev);
  };

  const handleChangeFormValue = (value: TReactionCompositinItems) => {
    onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        items: value
      }
    });
  };

  const handleDeleteComponent = (componentId: string) => {
    handleChangeFormValue({..._.omit(formData.fieldValues.items, [componentId])});
  };

  const handleAddComponent = (componentId: string) => {
    handleChangeFormValue({
      ...formData.fieldValues.items,
      [componentId]: {weight: null, coefficient: null}
    });
  };

  const handleAddToFavorites = (componentId: string) => {
    addToFavorites?.([componentId]);
  };

  const handleDeleteFromFavorites = (componentId: string) => {
    removeFromFavorites?.([componentId]);
  };

  const isBalanceAvailable =
    existedComponentsIds.length > 1 &&
    existedComponentsIds.filter((componentId) => !fieldValues.items[componentId]?.coefficient)
      .length === 1;

  const isEmpty = !existedComponentsIds.length;

  const tabs: ITabOption[] = useMemo(() => {
    return [
      {
        type: null,
        label: t(`common.favorites`),
        components: favorites
      },
      {
        type: EComponentBaseType.PURE,
        label: t(`enum.componentBaseType.${EComponentBaseType.PURE}`),
        components: pureList
      },
      {
        type: EComponentBaseType.HYPOTHETICAL,
        label: t(`enum.componentBaseType.${EComponentBaseType.HYPOTHETICAL}`),
        components: []
      },
      {
        type: EComponentBaseType.PSEUDO,
        label: t(`enum.componentBaseType.${EComponentBaseType.PSEUDO}`),
        components: []
      }
    ];
  }, [favorites, pureList, t]);

  return (
    <>
      <VStack gap="4px" w="100%">
        {/* NO ITEMS */}
        {isEmpty && (
          <Center w="100%" h="64px" border="1px solid" borderColor="border" borderRadius="4px">
            {t('jsfields.ReactionComposition.noItems')}
          </Center>
        )}

        {/* COMPONENTS TABLE */}
        {!isEmpty && (
          <ReactionCompositionTable
            isKinetic={!!fieldConfig.isKineticReaction}
            isReadonly={!!schema.readOnly}
            components={selectedComponents}
            items={items}
            onDeleteComponent={handleDeleteComponent}
            onChangeValue={handleChangeFormValue}
          />
        )}
        {/* ACTIONS */}
        {!schema.readOnly && (
          <HStack gap="8px" w="100%">
            <Button variant="ghost" size="smSquare" width="100%" onClick={handleToggleDialog}>
              {t('jsfields.ReactionComposition.addComponent')}
            </Button>
            <Button
              variant="ghost"
              size="smSquare"
              width="100%"
              isDisabled={!isBalanceAvailable}
              // TODO: Provide callbackCode using fieldConfig
              onClick={() => runElementCallback?.('')}
            >
              {t('jsfields.ReactionComposition.balance')}
            </Button>
          </HStack>
        )}
      </VStack>

      {/* ADDITIONAL FIELDS */}
      <Grid gridTemplateColumns={`auto 1fr`} gap="8px" w="100%" alignItems="center" mt="20px">
        <JsFieldName name={t('jsfields.ReactionComposition.balanceError')} />
        <InputNumber size="xs" variant="outline" value={fieldValues.balanceError} disabled />

        <JsFieldName name={t('jsfields.ReactionComposition.heatEffect')} />
        <InputNumber size="xs" variant="outline" value={fieldValues.heatEffect} disabled />
      </Grid>

      {isDialog && (
        <Dialog title={t('jsfields.ReactionComposition.addComponent')} onClose={handleToggleDialog}>
          <ComponentsLists
            tableSx={{height: 300, minWidth: 430}}
            tabs={tabs}
            selectedComponentsIds={existedComponentsIds}
            onAddComponent={handleAddComponent}
            onDeleteComponent={handleDeleteComponent}
            onAddToFavorite={(item) => handleAddToFavorites(item)}
            onDeleteFromFavorite={(item) => handleDeleteFromFavorites(item)}
          />
        </Dialog>
      )}
    </>
  );
};

export const ReactionCompositionJsField = memo(ReactionCompositionJsFieldFC);
