import {FC, ReactNode} from 'react';
import {observer} from 'mobx-react-lite';
import {JsFormConfigContext} from '@progress-fe/ui-kit';

import {useStore} from 'core';

const ProjectJsFormConfigContextFC: FC<{children: ReactNode}> = ({children}) => {
  const {techProcessStore, catalogsStore, favoriteComponents} = useStore();
  const {
    uiState,
    addComponentsToFavorite,
    removeComponentsFromFavorite,
    runModelCallback,
    runElementCallback
  } = techProcessStore;
  const {pureComponents, componentsDictionary} = catalogsStore;

  const {selectSubEntity} = uiState;

  return (
    <JsFormConfigContext.Provider
      value={{
        components: {
          favorites: favoriteComponents,
          pureList: pureComponents,
          dictionary: componentsDictionary
        },
        addToFavorites: addComponentsToFavorite,
        removeFromFavorites: removeComponentsFromFavorite,
        runModelCallback: runModelCallback,
        runElementCallback: runElementCallback,
        selectSubEntity: selectSubEntity
      }}
    >
      {children}
    </JsFormConfigContext.Provider>
  );
};

export const ProjectJsFormConfigContext = observer(ProjectJsFormConfigContextFC);
