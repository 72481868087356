import {tableAnatomy} from '@chakra-ui/anatomy';
import {createMultiStyleConfigHelpers} from '@chakra-ui/react';

const {definePartsStyle, defineMultiStyleConfig} = createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  thead: {
    th: {
      p: 0,
      textTransform: 'none',
      fontWeight: '400'
    }
  }
});

export const tableTheme = defineMultiStyleConfig({baseStyle});
