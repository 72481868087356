import {IComponent, IPureComponent} from '@progress-fe/core';
import {createContext} from 'react';

export type TJsFormComponentsConfig = {
  favorites?: IComponent[];
  pureList?: IPureComponent[];
  dictionary?: Record<string, IComponent>;
};

export type TJsFormConfigContext = {
  components?: TJsFormComponentsConfig;
  addToFavorites?: (components: string[]) => void;
  removeFromFavorites?: (components: string[]) => void;
  runModelCallback?: (callbackCode: string) => Promise<object | null>;
  runElementCallback?: (callbackCode: string) => Promise<object | null>;
  selectEntity?: (entityId: string) => void;
  selectSubEntity?: (entityId: string) => void;
};

export const JsFormConfigContext = createContext<TJsFormConfigContext>({});
