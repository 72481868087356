import {Box, Link} from '@chakra-ui/react';
import {ChevronLeftIcon} from '@chakra-ui/icons';
import {observer} from 'mobx-react-lite';
import {FC} from 'react';

interface IProps {
  selectedEntityName: string;
  onBack: () => void;
}

const SubEntityFormFC: FC<IProps> = ({selectedEntityName, onBack}) => {
  return (
    <Box p="12px 8px" position="absolute" top="0" left="0" bottom="0" right="0" bg="bg" zIndex={1}>
      <Link
        onClick={onBack}
        color="accent"
        display="flex"
        alignItems="center"
        _hover={{textDecoration: 'none'}}
      >
        <ChevronLeftIcon boxSize="20px" />
        {selectedEntityName}
      </Link>
    </Box>
  );
};

export const SubEntityForm = observer(SubEntityFormFC);
