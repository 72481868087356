import {VStack, Flex, Box, Grid, GridItem, Text} from '@chakra-ui/react';
import {FC, Fragment, memo} from 'react';
import {useT} from '@progress-fe/core';

import {DeleteButton} from '../../../../helpers';
import {NumberStepper, InputNumber, Select} from '../../../../inputs';
import {TColumnSpec, TColumnSpecItem} from '../../ColumnSpecsJsField.types';
import {ISelectOption} from '../../../../../interfaces';

interface IProps {
  spec: TColumnSpec;
  isReadonly: boolean;
  maxOrder: number;
  elementsOptions: ISelectOption<string>[];
  parametersOptions: ISelectOption<string>[];
  onChange: (changedSpec: TColumnSpec) => void;
  onDelete: () => void;
}

const SpecItemFC: FC<IProps> = ({
  spec,
  isReadonly,
  maxOrder,
  elementsOptions = [],
  parametersOptions = [],
  onChange,
  onDelete
}) => {
  const {t} = useT();

  const hadleOrderChange = (value: number) => {
    onChange({...spec, order: value});
  };

  const hadleItemChange = (idx: number, value: Partial<TColumnSpecItem>) => {
    const specCopy = structuredClone(spec);
    specCopy.items[idx] = {...specCopy.items[idx], ...value};

    onChange(specCopy);
  };
  return (
    <VStack w="100%" gap="8px">
      <Flex w="100%" gap="8px" align="center">
        <Box flexShrink={0}>
          <Text>{t('jsfields.ColumnSpecsJsField.order')}</Text>
        </Box>
        <NumberStepper
          value={spec.order}
          min={1}
          max={maxOrder}
          isReadonly={isReadonly}
          onChange={hadleOrderChange}
        />
        <Box flexShrink={0} flexBasis="24px">
          <DeleteButton onClick={onDelete} isDisabled={isReadonly} />
        </Box>
      </Flex>

      <Grid
        templateColumns="100px 170px 1fr"
        gap="8px"
        border="1px solid"
        borderColor="border"
        borderRadius="4px"
        alignItems="center"
        p="8px"
      >
        <GridItem>
          <Text>{t('common.element')}</Text>
        </GridItem>
        <GridItem>
          <Text>{t('common.parametr')}</Text>
        </GridItem>
        <GridItem>
          <Text>{t('common.value')}</Text>
        </GridItem>
        {spec.items.map((item, idx) => {
          return (
            <Fragment key={idx}>
              <Select
                name="element"
                options={elementsOptions}
                isDisabled={isReadonly}
                placeholder={t('common.select')}
                value={elementsOptions.find((opt) => opt.value === item.element)}
                onChange={(selectValue) => {
                  hadleItemChange(idx, {element: (selectValue as ISelectOption<string>).value});
                }}
              />
              <Select
                name="parametr"
                options={parametersOptions}
                isDisabled={isReadonly}
                placeholder={t('common.select')}
                value={parametersOptions.find((opt) => opt.value === item.parameter)}
                onChange={(selectValue) => {
                  hadleItemChange(idx, {parameter: (selectValue as ISelectOption<string>).value});
                }}
              />
              <InputNumber
                min={0}
                size="xs"
                variant="outline"
                isOnChangeOnlyOnBlur
                disabled={isReadonly}
                value={item.value || ''}
                onChange={(val) => {
                  hadleItemChange(idx, {value: val});
                }}
              />
            </Fragment>
          );
        })}
      </Grid>
    </VStack>
  );
};

export const SpecItem = memo(SpecItemFC);
