import {Grid, IconButton} from '@chakra-ui/react';
import {FieldProps} from '@rjsf/utils';

import {JsFieldName} from '../../jsFormBase';
import {InputNumber} from '../../inputs';
import {Svg} from '../../helpers';

interface IProps extends FieldProps {
  isFloat?: boolean;
}

type TFormData = {
  value: string | null;
  locked: boolean;
};

/**
 * Component renders "/schemas/jsf-number-lock" or "/schemas/jsf-float-lock" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is TFormData.
 */
const NumberLockJsFieldFC = (props: IProps) => {
  const formData = props.formData as TFormData;

  const handleChangeValue = (value: string | null) => {
    const newFormData: TFormData = {...formData, value: value || null};
    props.onChange(newFormData);
  };

  const handleChangeLock = (locked: boolean) => {
    const newFormData: TFormData = {...formData, locked};
    props.onChange(newFormData);
  };

  return (
    <Grid gridTemplateColumns="minmax(0, 180px) 1fr 24px" alignItems="center" gap="4px">
      <JsFieldName name={props.schema.title} />

      <InputNumber
        size="xs"
        variant="outline"
        isOnChangeOnlyOnBlur
        min={props.schema.minimum}
        max={props.schema.maximum}
        exclusiveMin={props.schema.exclusiveMinimum}
        exclusiveMax={props.schema.exclusiveMaximum}
        value={formData.value || undefined}
        isFloat={props.isFloat}
        disabled={props.schema.readOnly}
        rightElement={props.schema.$comment ? <>{props.schema.$comment}</> : undefined}
        onChange={(value) => handleChangeValue(value)}
      />

      <IconButton
        size="smSquare"
        aria-label=""
        variant="ghost"
        isDisabled={props.schema.readOnly}
        icon={<Svg size="s12" name={formData.locked ? 'Locked' : 'Unlocked'} />}
        onClick={() => handleChangeLock(!formData.locked)}
      />
    </Grid>
  );
};

export const NumberLockJsField = NumberLockJsFieldFC;
