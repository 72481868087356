import {FC, memo} from 'react';
import {VStack, IconButton, Box} from '@chakra-ui/react';
import cn from 'clsx';

import {TOilBlendModelFormData, TRangeValue} from '../../../../OilBlendModelFormData.types';
import {Svg} from '../../../../../../helpers';
import {InputNumber} from '../../../../../../inputs';

import s from './OilBlendRange.module.css';

interface IProps {
  rangeValue: TRangeValue;
  formData: TOilBlendModelFormData;
  isFirst: boolean;
  isLast: boolean;
  isReadOnly: boolean;
  onChange: (newValue: TRangeValue) => void;
  onDelete: () => void;
  suffix?: string;
  className?: string;
}

const OilBlendRangeFC: FC<IProps> = ({
  rangeValue,
  formData,
  isFirst,
  isLast,
  suffix,
  className,
  isReadOnly,
  onChange,
  onDelete
}) => {
  const fieldConfig = formData.fieldConfig;

  const handleChanges = (name: string, value: string | null) => {
    if (value === null) {
      return;
    }

    const editedRange = {...rangeValue, [name]: value};
    onChange(editedRange);
  };

  return (
    <VStack
      className={cn(s.rangeColumn, isReadOnly && s.readonly, className)}
      align="stretch"
      justify="center"
      gap="4px"
      flexShrink="0"
    >
      <InputNumber
        size="xs"
        variant="outline"
        sx={{width: '100%'}}
        value={rangeValue[fieldConfig.min.name] || undefined}
        rightElement={suffix ? <>{suffix}</> : undefined}
        max={rangeValue[fieldConfig.max.name]}
        disabled={isFirst || isReadOnly}
        isOnChangeOnlyOnBlur
        onChange={(value) => {
          handleChanges(fieldConfig.min.name, value);
        }}
      />
      <InputNumber
        size="xs"
        variant="outline"
        sx={{width: '100%'}}
        value={rangeValue[fieldConfig.max.name] || undefined}
        min={rangeValue[fieldConfig.min.name]}
        rightElement={suffix ? <>{suffix}</> : undefined}
        disabled={isLast || isReadOnly}
        isOnChangeOnlyOnBlur
        onChange={(value) => {
          handleChanges(fieldConfig.max.name, value);
        }}
      />
      {fieldConfig.fields.map((field) => (
        <InputNumber
          key={field.name}
          name={field.name}
          size="xs"
          variant="outline"
          disabled={isReadOnly}
          isOnChangeOnlyOnBlur
          value={rangeValue[field.name]}
          onChange={(value) => {
            handleChanges(field.name, value);
          }}
        />
      ))}
      {!isReadOnly && (
        <>
          {!isFirst ? (
            <IconButton
              className={s.deleteRangeBtn}
              size="smSquare"
              w="100%"
              aria-label=""
              variant="ghost"
              isDisabled={isReadOnly}
              icon={<Svg size="s12" name="Cross" />}
              onClick={onDelete}
            />
          ) : (
            <Box h="24px" />
          )}
        </>
      )}
    </VStack>
  );
};

export const OilBlendRange = memo(OilBlendRangeFC);
