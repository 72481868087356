import {FC, memo} from 'react';
import {ToastContainer as ToastifyContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import './ToastContainer.css';

const ToastContainerFC: FC = () => {
  return <ToastifyContainer stacked position={'bottom-right'} hideProgressBar />;
};

export const ToastContainer = memo(ToastContainerFC);
