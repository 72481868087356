/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ElementData
 */
export interface ElementData {
  /**
   *
   * @type {string}
   * @memberof ElementData
   */
  elementName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ElementData
   */
  elementModelName?: string | null;
  /**
   *
   * @type {string}
   * @memberof ElementData
   */
  elementType?: string | null;
  /**
   *
   * @type {string}
   * @memberof ElementData
   */
  templateCode?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ElementData
   */
  isTargetHandle?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ElementData
   */
  isSourceHandle?: boolean | null;
}

/**
 * Check if a given object implements the ElementData interface.
 */
export function instanceOfElementData(value: object): value is ElementData {
  return true;
}

export function ElementDataFromJSON(json: any): ElementData {
  return ElementDataFromJSONTyped(json, false);
}

export function ElementDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElementData {
  if (json == null) {
    return json;
  }
  return {
    elementName: json['elementName'] == null ? undefined : json['elementName'],
    elementModelName: json['elementModelName'] == null ? undefined : json['elementModelName'],
    elementType: json['elementType'] == null ? undefined : json['elementType'],
    templateCode: json['templateCode'] == null ? undefined : json['templateCode'],
    isTargetHandle: json['isTargetHandle'] == null ? undefined : json['isTargetHandle'],
    isSourceHandle: json['isSourceHandle'] == null ? undefined : json['isSourceHandle']
  };
}

export function ElementDataToJSON(value?: ElementData | null): any {
  if (value == null) {
    return value;
  }
  return {
    elementName: value['elementName'],
    elementModelName: value['elementModelName'],
    elementType: value['elementType'],
    templateCode: value['templateCode'],
    isTargetHandle: value['isTargetHandle'],
    isSourceHandle: value['isSourceHandle']
  };
}
