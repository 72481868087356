/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {MarkerType} from './MarkerType';
import {MarkerTypeFromJSON, MarkerTypeFromJSONTyped, MarkerTypeToJSON} from './MarkerType';

/**
 *
 * @export
 * @interface MarkerProperties
 */
export interface MarkerProperties {
  /**
   *
   * @type {MarkerType}
   * @memberof MarkerProperties
   */
  type?: MarkerType;
  /**
   *
   * @type {string}
   * @memberof MarkerProperties
   */
  color: string;
  /**
   *
   * @type {number}
   * @memberof MarkerProperties
   */
  height?: number;
  /**
   *
   * @type {number}
   * @memberof MarkerProperties
   */
  width?: number;
}

/**
 * Check if a given object implements the MarkerProperties interface.
 */
export function instanceOfMarkerProperties(value: object): value is MarkerProperties {
  if (!('color' in value) || value['color'] === undefined) return false;
  return true;
}

export function MarkerPropertiesFromJSON(json: any): MarkerProperties {
  return MarkerPropertiesFromJSONTyped(json, false);
}

export function MarkerPropertiesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MarkerProperties {
  if (json == null) {
    return json;
  }
  return {
    type: json['type'] == null ? undefined : MarkerTypeFromJSON(json['type']),
    color: json['color'],
    height: json['height'] == null ? undefined : json['height'],
    width: json['width'] == null ? undefined : json['width']
  };
}

export function MarkerPropertiesToJSON(value?: MarkerProperties | null): any {
  if (value == null) {
    return value;
  }
  return {
    type: MarkerTypeToJSON(value['type']),
    color: value['color'],
    height: value['height'],
    width: value['width']
  };
}
