import {FC} from 'react';
import {observer} from 'mobx-react-lite';
import {i18n} from '@progress-fe/core';

import {SideMenu} from 'ui-kit';
import {TProjectsMenu} from 'core/types';
import {IMenuOption} from 'core/interfaces';

interface IProps {
  activeMenuItem: TProjectsMenu;
  updateActiveMenuItem: (item: TProjectsMenu) => void;
}

const MENU_ITEMS: IMenuOption<TProjectsMenu>[] = [
  {icon: 'All', label: i18n.t('common.all'), value: 'All'},
  {icon: 'Mix', label: i18n.t('projects.techProcesses'), value: 'TechProcess'},
  {icon: 'Production', label: i18n.t('projects.samplesAndOil'), value: 'Mix'},
  {icon: 'Shared', label: i18n.t('common.share'), value: 'Shared'},
  {icon: 'Favorite', label: i18n.t('common.favorites'), value: 'Favorites'},
  {icon: 'Bin', label: i18n.t('common.bin'), value: 'Bin'}
];

const ProjectMenuFC: FC<IProps> = ({activeMenuItem, updateActiveMenuItem}) => {
  return (
    <SideMenu
      options={MENU_ITEMS}
      activeMenuItem={activeMenuItem}
      onSelect={updateActiveMenuItem}
    />
  );
};

export const ProjectMenu = observer(ProjectMenuFC);
