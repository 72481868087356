import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Flex, GridItem, Text} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {Svg, SelectTabs, ISelectOption} from '@progress-fe/ui-kit';
import {EComponentBaseType, IComponent} from '@progress-fe/core';

import {
  HypotheticalComponents,
  FavoriteComponents,
  PseudoComponents,
  PureComponents
} from './components';

interface IProps {
  tabs: ITabOption[];
  selectedComponentsIds: string[];
  title?: string;
  tableSx?: SystemStyleObject;
  onAddComponent?: (value: string) => void;
  onDeleteComponent?: (value: string) => void;
  onAddAllComponents?: (values: string[]) => void;
  onDeleteAllComponents?: (values: string[]) => void;
  onAddToFavorite?: (component: string) => void;
  onDeleteFromFavorite?: (component: string) => void;
  onClose?: () => void;
}

export interface ITabOption {
  type: EComponentBaseType | null;
  label: string;
  components: IComponent[];
  readonly?: boolean;
}

const ComponentsListsFC: FC<IProps> = ({
  title,
  tabs = [],
  selectedComponentsIds,
  onAddComponent = () => {},
  onDeleteComponent = () => {},
  onAddToFavorite,
  onDeleteFromFavorite,
  tableSx,
  onClose
}) => {
  const [selectedType, setSelectedType] = useState<EComponentBaseType | null>(
    tabs[0]?.type ?? null
  );

  const [favoritesSet, setFavoritesSet] = useState<Set<string>>(new Set());

  useEffect(() => {
    const favoriteTab = tabs.find((tab) => tab.type === null);
    if (!favoriteTab) {
      return;
    }

    const ids = favoriteTab.components?.map((item) => item.uuid) || [];
    setFavoritesSet(new Set(ids));
  }, [tabs]);

  const selectedSet = useMemo(() => new Set(selectedComponentsIds), [selectedComponentsIds]);

  const handleToggleFavorite = useCallback(
    (componentId: string) => {
      if (favoritesSet.has(componentId)) {
        onDeleteFromFavorite?.(componentId);
      } else {
        onAddToFavorite?.(componentId);
      }
    },
    [favoritesSet, onAddToFavorite, onDeleteFromFavorite]
  );

  const options: ISelectOption<EComponentBaseType | null>[] = useMemo(() => {
    return tabs.map((tab) => ({
      label: tab.label,
      value: tab.type,
      readonly: false
    }));
  }, [tabs]);

  const components = useMemo(() => {
    return tabs.find((tab) => tab.type === selectedType)?.components || [];
  }, [selectedType, tabs]);

  return (
    <Box minW="400px">
      {title && onClose && (
        <Flex h="26px" justify="space-between">
          <Text fontSize="12px" fontWeight={600}>
            {title}
          </Text>
          {onClose && <Svg name="Cross" onClick={onClose} />}
        </Flex>
      )}

      {options.length > 1 && (
        <SelectTabs
          value={selectedType}
          options={options}
          onChange={(newValue) =>
            setSelectedType(!!newValue ? (newValue as EComponentBaseType) : null)
          }
        />
      )}

      <Box m="8px 0 0 0">
        {/* SELECTED CHEMICAL COMPONENT TYPE */}
        <GridItem>
          {selectedType === null && (
            <FavoriteComponents
              selectedComponents={selectedSet}
              components={components}
              onAddComponent={onAddComponent}
              onDeleteComponent={onDeleteComponent}
              removeFromFavorite={onDeleteFromFavorite}
              tableSx={tableSx}
            />
          )}

          {selectedType === EComponentBaseType.PURE && (
            <PureComponents
              favorites={favoritesSet}
              components={components}
              selectedComponents={selectedSet}
              onAddComponent={onAddComponent}
              onDeleteComponent={onDeleteComponent}
              tableSx={tableSx}
              onToggleFavorite={
                onAddToFavorite && onDeleteFromFavorite ? handleToggleFavorite : undefined
              }
            />
          )}

          {selectedType === EComponentBaseType.HYPOTHETICAL && (
            <HypotheticalComponents tableSx={tableSx} />
          )}

          {selectedType === EComponentBaseType.PSEUDO && <PseudoComponents tableSx={tableSx} />}
        </GridItem>
      </Box>
    </Box>
  );
};

export const ComponentsLists = ComponentsListsFC;
