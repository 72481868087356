import {FieldProps} from '@rjsf/utils';
import {FC, memo, useContext} from 'react';
import {Button, Center, VStack} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';

import {JsFormConfigContext} from '../../../contexts';

import {TCatalyst, TCatalystsListFormData} from './CatalystsListJsField.types';
import {CatalystItem} from './components';

interface IProps extends FieldProps {
  formData: TCatalystsListFormData;
}

const CatalystsListJsFieldFC: FC<IProps> = ({schema, formData, onChange}) => {
  const {selectSubEntity} = useContext(JsFormConfigContext) || {};
  const {t} = useT();
  const {fieldValues} = formData;

  const isEmpty = !fieldValues.length;

  const handleChangeFormValue = (value: TCatalyst[]) => {
    onChange({
      ...formData,
      fieldValues: value
    });
  };

  const handleAddCatalyst = () => {
    // TODO: real request to create entity
    const id = uuidv4();
    const newCatalyst: TCatalyst = {
      id,
      name: id
    };

    const newValues = [...fieldValues, newCatalyst];

    handleChangeFormValue(newValues);
  };

  const handleSelectCatalyst = (catalyst: TCatalyst) => {
    selectSubEntity?.(catalyst.id);
  };

  const handleDeleteCatalyst = (catalyst: TCatalyst) => {
    // TODO: real request to delete entity
    const newValues = fieldValues.filter((item) => item.id !== catalyst.id);

    handleChangeFormValue(newValues);
  };

  return (
    <VStack gap="8px" w="100%">
      {/* NO ITEMS */}
      {isEmpty && (
        <Center w="100%" h="64px" border="1px solid" borderColor="border" borderRadius="4px">
          {t('jsfields.CatalystsList.noItems')}
        </Center>
      )}
      {!isEmpty && (
        <VStack gap="4px" w="100%">
          {fieldValues.map((item) => (
            <CatalystItem
              key={item.id}
              catalyst={item}
              isReadonly={!!schema.readOnly}
              onSelect={() => handleSelectCatalyst(item)}
              onDelete={() => handleDeleteCatalyst(item)}
            />
          ))}
        </VStack>
      )}
      {/* ACTIONS */}c
      {!schema.readOnly && (
        <Button variant="ghost" size="smSquare" width="100%" onClick={handleAddCatalyst}>
          {t('actions.add')}
        </Button>
      )}
    </VStack>
  );
};

export const CatalystsListJsField = memo(CatalystsListJsFieldFC);
