/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {RJSFSchemas} from './RJSFSchemas';
import {RJSFSchemasFromJSON, RJSFSchemasFromJSONTyped, RJSFSchemasToJSON} from './RJSFSchemas';
import type {WorkZone} from './WorkZone';
import {WorkZoneFromJSON, WorkZoneFromJSONTyped, WorkZoneToJSON} from './WorkZone';

/**
 *
 * @export
 * @interface UpdateOut
 */
export interface UpdateOut {
  /**
   *
   * @type {string}
   * @memberof UpdateOut
   */
  name: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateOut
   */
  dataChanged: boolean;
  /**
   *
   * @type {{ [key: string]: RJSFSchemas; }}
   * @memberof UpdateOut
   */
  schemas: {[key: string]: RJSFSchemas} | null;
  /**
   *
   * @type {WorkZone}
   * @memberof UpdateOut
   */
  workzone: WorkZone | null;
}

/**
 * Check if a given object implements the UpdateOut interface.
 */
export function instanceOfUpdateOut(value: object): value is UpdateOut {
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('dataChanged' in value) || value['dataChanged'] === undefined) return false;
  if (!('schemas' in value) || value['schemas'] === undefined) return false;
  if (!('workzone' in value) || value['workzone'] === undefined) return false;
  return true;
}

export function UpdateOutFromJSON(json: any): UpdateOut {
  return UpdateOutFromJSONTyped(json, false);
}

export function UpdateOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOut {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'],
    dataChanged: json['data_changed'],
    schemas: json['schemas'] == null ? null : mapValues(json['schemas'], RJSFSchemasFromJSON),
    workzone: WorkZoneFromJSON(json['workzone'])
  };
}

export function UpdateOutToJSON(value?: UpdateOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
    data_changed: value['dataChanged'],
    schemas: value['schemas'] == null ? null : mapValues(value['schemas'], RJSFSchemasToJSON),
    workzone: WorkZoneToJSON(value['workzone'])
  };
}
