import {FC, useState} from 'react';
import {Grid, IconButton} from '@chakra-ui/react';
import {SystemStyleObject} from '@chakra-ui/styled-system';
import {IComponent} from '@progress-fe/core';

import {Input} from '../../../../inputs';
import {VirtualTable} from '../../../../tables';
import {ISimpleTableColumn, ISimpleTableRow} from '../../../../../interfaces';
import {Svg} from '../../../../helpers';

interface IProps {
  components: IComponent[];
  selectedComponents: Set<string>;
  onAddComponent: (component: string) => void;
  onDeleteComponent: (component: string) => void;
  removeFromFavorite?: (component: string) => void;
  tableSx?: SystemStyleObject;
}

const FavoriteComponentsFC: FC<IProps> = ({
  components,
  selectedComponents,
  onAddComponent,
  onDeleteComponent,
  removeFromFavorite,
  tableSx
}) => {
  const [query, setQuery] = useState('');

  const columns: ISimpleTableColumn[] = [
    {name: 'Название'},
    {name: 'Формула'},
    {name: 'Структура'},
    {
      name: '',
      width: '24px',
      render: (id: string) => (
        removeFromFavorite && <IconButton
          size="smSquare"
          aria-label=""
          variant="transparent"
          icon={<Svg size="s12" name="Minus" />}
          onClick={() => removeFromFavorite(id)}
        />
      )
    }
  ];

  const rows: ISimpleTableRow<string>[] = components
    .filter((c) => c.name.toLowerCase().includes(query.toLowerCase()))
    .map((opt) => ({
      key: opt.uuid,
      isDisabled: false,
      values: [opt.name, opt.formula || '—', opt.structure || '—', opt.uuid],
      isSelected: selectedComponents.has(opt.uuid)
    }));

  return (
    <Grid templateRows="1fr" gap="8px">
      <Grid gridTemplateColumns="1fr" gap="8px">
        <Input name='search' size="xs" variant="outline" value={query} placeholder="Поиск" onChange={setQuery} />
      </Grid>
      <VirtualTable
        isCheckboxShown
        rows={rows}
        columns={columns}
        noItemsTitle="Нет избранных компонентов"
        tableSx={tableSx}
        onSelect={(value, isSelected) => {
          if (isSelected) {
            onAddComponent(value);
          } else {
            onDeleteComponent(value);
          }
        }}
      />
    </Grid>
  );
};

export const FavoriteComponents = FavoriteComponentsFC;
