import {cast, flow, getSnapshot, Instance, types} from 'mobx-state-tree';
import {TRFEdgeDataConfig, TRFGraphDataConfig, TRFGraphZone} from '@progress-fe/rf-core';
import {Edge, Node} from '@xyflow/react';

import {ETaskType} from 'core/enums';
import {RequestModel, ResetModel} from 'core/models';
import {TechProcessApi, TechprocessCalculationTaskOut, WorkZone} from 'api';

const ProjectTask = types
  .compose(
    ResetModel,
    types.model('ProjectTask', {
      projectUuid: '',
      checkpointUuid: '',
      graphzoneLastUpdate: new Date(),

      type: types.optional(types.enumeration(Object.values(ETaskType)), ETaskType.Base),
      nodes: types.optional(types.array(types.frozen<Node<TRFGraphDataConfig>>()), []),
      edges: types.optional(types.array(types.frozen<Edge<TRFEdgeDataConfig>>()), []),
      description: types.optional(types.string, ''),
      fetchRequest: types.optional(RequestModel, {})
    })
  )
  .actions((self) => ({
    _loadTaskDetails: flow(function* () {
      const response: TechprocessCalculationTaskOut = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetTechprocessCalculationTasks.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );
      self.description = response?.description || '';
    }),
    _loadGraphZone: flow(function* () {
      const response: WorkZone | null = yield self.fetchRequest.send(
        TechProcessApi.techProcessGetCompGraph.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid
        }
      );

      if (response) {
        self.nodes = cast((response as TRFGraphZone).nodes);
        self.edges = cast((response as TRFGraphZone).edges);
        self.graphzoneLastUpdate = new Date();
      } else {
        self.nodes = cast([]);
        self.edges = cast([]);
        self.graphzoneLastUpdate = new Date();
      }
    }),
    _updateDescription: flow(function* () {
      yield self.fetchRequest.send(
        TechProcessApi.techProcessSetTechprocessCalculationTask.bind(TechProcessApi),
        {
          projectUuid: self.projectUuid,
          checkpointUuid: self.checkpointUuid,
          techprocessCalculationTaskUpdate: {
            type: self.type,
            description: self.description
          }
        }
      );
    })
  }))
  .actions((self) => ({
    init: flow(function* (projectUuid: string, checkpointUuid: string) {
      self.resetModel();
      self.projectUuid = projectUuid;
      self.checkpointUuid = checkpointUuid;

      yield self._loadTaskDetails();
      yield self._loadGraphZone();
    })
  }))
  .actions((self) => ({
    setType(type: ETaskType): void {
      self.type = type;
    },
    setDescription: flow(function* (description: string) {
      self.description = description;
      yield self._updateDescription();
    })
  }))
  .views((self) => ({
    get nodeList() {
      return getSnapshot(self.nodes);
    },
    get edgeList() {
      return getSnapshot(self.edges);
    }
  }));

export type TProjectTaskModel = Instance<typeof ProjectTask>;

export {ProjectTask};
