import {VStack, Grid, GridItem, Divider, Text} from '@chakra-ui/react';
import {FC, memo} from 'react';
import {IComponent, useT} from '@progress-fe/core';

import {DeleteButton} from '../../../../helpers';
import {InputNumber} from '../../../../inputs';
import {
  TReactionCompositinItems,
  TReactionCompositionItem
} from '../../ReactionsParametersTableJsField.types';

interface IProps {
  isKinetic: boolean;
  isReadonly: boolean;
  items: TReactionCompositinItems;
  components: IComponent[];
  onChangeValue: (newValues: TReactionCompositinItems) => void;
  onDeleteComponent: (componentId: string) => void;
}

const ReactionCompositionTableFC: FC<IProps> = ({
  isKinetic,
  isReadonly,
  components,
  items,
  onChangeValue,
  onDeleteComponent
}) => {
  const {t} = useT();

  const gridTemplate = isKinetic ? '100px 1fr 1fr 50px 50px 24px' : '100px 1fr 1fr 24px';

  const hangleChanges = (componentId: string, value: Partial<TReactionCompositionItem>) => {
    const newValue = {...items};

    if (!newValue[componentId]) {
      return;
    }

    newValue[componentId] = {...newValue[componentId], ...value};

    onChangeValue(newValue);
  };

  return (
    <VStack gap="4px" w="100%" border="1px solid" borderColor="border" borderRadius="4px" p="4px">
      {/* CHEMICAL COMPONENTS HEADER */}
      <Grid gap="4px" alignItems="center" w="100%" templateColumns={gridTemplate}>
        <GridItem>
          <Text noOfLines={1}>{t('jsfields.ReactionComposition.table.component')}</Text>
        </GridItem>
        <GridItem>
          {' '}
          <Text noOfLines={1}>{t('jsfields.ReactionComposition.table.weight')}</Text>
        </GridItem>
        <GridItem>
          {' '}
          <Text noOfLines={1}>{t('jsfields.ReactionComposition.table.coefficient')}</Text>
        </GridItem>
        {isKinetic && (
          <>
            <GridItem>
              {' '}
              <Text noOfLines={1}>{t('jsfields.ReactionComposition.table.forwardReaction')}</Text>
            </GridItem>
            <GridItem>
              {' '}
              <Text noOfLines={1}>{t('jsfields.ReactionComposition.table.reverseReaction')}</Text>
            </GridItem>
            <GridItem />
          </>
        )}
      </Grid>

      <Divider />

      {/* CHEMICAL COMPONENTS LIST ITEMS */}
      {components.map((component) => (
        <Grid key={component.uuid} gap="4px" w="100%" templateColumns={gridTemplate}>
          <GridItem>{component.name}</GridItem>
          <GridItem>{items[component.uuid]?.weight}</GridItem>
          <InputNumber
            isFloat
            min={0}
            size="xs"
            variant="outline"
            isOnChangeOnlyOnBlur
            disabled={isReadonly}
            value={items[component.uuid]?.coefficient || undefined}
            onChange={(value) => {
              hangleChanges(component.uuid, {coefficient: value});
            }}
          />
          {isKinetic && (
            <>
              <InputNumber
                isFloat
                min={0}
                size="xs"
                variant="outline"
                isOnChangeOnlyOnBlur
                disabled={isReadonly}
                value={items[component.uuid]?.forwardReactionOrder || undefined}
                onChange={(value) => {
                  hangleChanges(component.uuid, {forwardReactionOrder: value});
                }}
              />
              <InputNumber
                isFloat
                min={0}
                size="xs"
                variant="outline"
                isOnChangeOnlyOnBlur
                disabled={isReadonly}
                value={items[component.uuid]?.reverseReactionOrder || undefined}
                onChange={(value) => {
                  hangleChanges(component.uuid, {reverseReactionOrder: value});
                }}
              />
            </>
          )}
          <DeleteButton onClick={() => onDeleteComponent(component.uuid)} />
        </Grid>
      ))}
    </VStack>
  );
};

export const ReactionCompositionTable = memo(ReactionCompositionTableFC);
