import {FC, memo, useEffect, useState} from 'react';
import {Textarea as ChakraTextarea} from '@chakra-ui/react';

interface IProps {
  size?: 'xs';
  rows?: number;
  variant?: 'outline';
  value?: string;
  disabled?: boolean;
  isOnChangeOnlyOnBlur?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

const TextareaFC: FC<IProps> = ({
  rows,
  value,
  disabled,
  size = 'xs',
  variant = 'outline',
  isOnChangeOnlyOnBlur,
  onChange,
  onBlur
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  return (
    <ChakraTextarea
      size={size}
      rows={rows}
      variant={variant}
      value={inputValue}
      disabled={disabled}
      onChange={(event) => {
        setInputValue(event.target.value);
        if (!isOnChangeOnlyOnBlur) {
          onChange?.(event.target.value);
        }
      }}
      onBlur={(event) => {
        if (isOnChangeOnlyOnBlur) {
          onChange?.(event.target.value);
        }
        onBlur?.(event.target.value);
      }}
    />
  );
};

export const Textarea = memo(TextareaFC);
