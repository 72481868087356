import {FieldProps} from '@rjsf/utils';
import {FC, memo, useEffect, useState} from 'react';
import {useT} from '@progress-fe/core';

import {DialogableContainer} from '../../containers';

import {OilSampleModelTable} from './components';
import {TOilSampleModelFormData, TRangeValue} from './OilSampleModelTableFormData.types';

interface IProps extends FieldProps {}

const OilSampleModelTableJsFieldFC: FC<IProps> = ({schema, onChange, ...rest}) => {
  const {t} = useT();
  const [ranges, setRanges] = useState<TRangeValue[]>([]);

  const formData = rest.formData as TOilSampleModelFormData;
  const fieldConfig = formData.fieldConfig;

  console.info('OilSampleModelTableJsFieldFC');

  useEffect(() => {
    if (!formData.fieldValues.value.length) {
      setRanges([
        {
          [fieldConfig.min.name]: fieldConfig.min.value.toString(),
          [fieldConfig.max.name]: fieldConfig.max.value.toString()
        }
      ]);
      return;
    }

    setRanges(formData.fieldValues.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeFormValue = (value: TRangeValue[]) => {
    onChange({
      ...formData,
      fieldValues: {
        ...formData.fieldValues,
        value
      }
    });
  };

  const handleCreateRange = (splitValue: string, rangeIdxToSplit: number): void => {
    const newRanges = [...ranges];
    const addedRanges = [
      {...ranges[rangeIdxToSplit], max: splitValue},
      {...ranges[rangeIdxToSplit], min: splitValue}
    ];
    newRanges.splice(rangeIdxToSplit, 1, ...addedRanges);

    setRanges(newRanges);
    handleChangeFormValue(newRanges);
  };

  const handleDeleteRange = (idx: number): void => {
    // we can't delete the first one
    if (!idx) {
      return;
    }

    const deletedRange = ranges[idx];
    const prevRange = {
      ...ranges[idx - 1],
      [fieldConfig.max.name]: deletedRange[fieldConfig.max.name]
    };

    const newRanges = [...ranges];
    newRanges.splice(idx - 1, 2, prevRange);

    setRanges(newRanges);
  };

  const handleRangeChange = (rangeVal: TRangeValue, idx: number) => {
    const newRanges = structuredClone(ranges);
    const prev = newRanges[idx - 1];
    const next = newRanges[idx + 1];
    if (prev && prev[fieldConfig.max.name] !== rangeVal[fieldConfig.min.name]) {
      prev[fieldConfig.max.name] = rangeVal[fieldConfig.min.name];
    }

    if (next && next[fieldConfig.min.name] !== rangeVal[fieldConfig.max.name]) {
      next[fieldConfig.min.name] = rangeVal[fieldConfig.max.name];
    }

    newRanges[idx] = {...rangeVal};

    setRanges(newRanges);
    handleChangeFormValue(newRanges);
  };

  return (
    <DialogableContainer title={t('jsfields.OilSampleModelTable.dialogTitle')}>
      {(isDialog, handleToggleDialog) => {
        return (
          <OilSampleModelTable
            ranges={ranges}
            isDialog={isDialog}
            formData={formData}
            isReadOnly={!!schema.readOnly}
            suffix={schema.$comment}
            onCreateRange={handleCreateRange}
            onDeleteRange={handleDeleteRange}
            onRangeChanged={handleRangeChange}
            onToggleDialog={handleToggleDialog}
          />
        );
      }}
    </DialogableContainer>
  );
};

export const OilSampleModelTableJsField = memo(OilSampleModelTableJsFieldFC);
