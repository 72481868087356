export enum EProjectSortType {
  Asc = 'asc',
  Desc = 'desc'
}

export enum EProjectSortField {
  Name = 'name',
  Type = 'type',
  Deleted = 'deleted',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at'
}
