import {types} from 'mobx-state-tree';
import {EStructureItem} from '@progress-fe/core';

import {ResetModel} from 'core/models';

const UiState = types
  .compose(
    ResetModel,
    types.model('UiState', {
      entityId: '',
      entityType: types.optional(
        types.enumeration(Object.values(EStructureItem)),
        EStructureItem.Settings
      ),
      subEntityId: types.maybeNull(types.string)
    })
  )
  .actions((self) => ({
    select(type: EStructureItem, id?: string | null): void {
      self.entityType = type;
      self.entityId = id || '';
      self.subEntityId = null;
    },
    isAlreadySelected(type: EStructureItem, id?: string | null): boolean {
      return self.entityType === type || self.entityId === (id || '');
    },
    selectSubEntity(id: string | null): void {
      self.subEntityId = id;
    }
  }))
  .views(() => ({}));

export {UiState};
