import {useCallback, useState} from 'react';
import {hasValue} from '@progress-fe/core';
import _ from 'lodash';

import {useDebouncedCallback} from './useDebouncedCallback';

interface IProps<T, U> {
  config: T | null;
  values: U | null;
  onChange: (formData: unknown) => void;
}

const DELAY_MS = 250;

const useJsFieldFormData = <T, U>({config, values, onChange}: IProps<T, U>) => {
  const [fieldConfig, setFieldConfig] = useState<T | null>(config);
  const [fieldValues, setFieldValues] = useState<U | null>(values);

  const debouncedOnChange = useDebouncedCallback(onChange, DELAY_MS, []);

  // Field values can be changed by user
  const onChangeFieldValues = useCallback(
    (newFieldValues: U) => {
      const formData = hasValue(fieldConfig)
        ? {fieldConfig: fieldConfig, fieldValues: newFieldValues}
        : {...newFieldValues};

      setFieldValues(newFieldValues);
      debouncedOnChange(formData);
    },
    [fieldConfig, debouncedOnChange]
  );

  // Field config can not be changed by user
  const onChangeFieldConfig = useCallback(
    (newFieldConfig: T | null | undefined) => {
      if (!_.isEqual(fieldConfig, newFieldConfig)) {
        setFieldConfig(newFieldConfig || null);
      }
    },
    [fieldConfig]
  );

  return {fieldConfig, fieldValues, onChangeFieldValues, onChangeFieldConfig};
};

export {useJsFieldFormData};
