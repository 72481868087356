/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {AppProjectsTechprocessWorkzoneSchemasElement} from './AppProjectsTechprocessWorkzoneSchemasElement';
import {
  AppProjectsTechprocessWorkzoneSchemasElementFromJSON,
  AppProjectsTechprocessWorkzoneSchemasElementFromJSONTyped,
  AppProjectsTechprocessWorkzoneSchemasElementToJSON
} from './AppProjectsTechprocessWorkzoneSchemasElement';
import type {WorkZoneEdgesInner} from './WorkZoneEdgesInner';
import {
  WorkZoneEdgesInnerFromJSON,
  WorkZoneEdgesInnerFromJSONTyped,
  WorkZoneEdgesInnerToJSON
} from './WorkZoneEdgesInner';

/**
 *
 * @export
 * @interface WorkZone
 */
export interface WorkZone {
  /**
   *
   * @type {Array<WorkZoneEdgesInner>}
   * @memberof WorkZone
   */
  edges: Array<WorkZoneEdgesInner>;
  /**
   *
   * @type {Array<AppProjectsTechprocessWorkzoneSchemasElement>}
   * @memberof WorkZone
   */
  nodes: Array<AppProjectsTechprocessWorkzoneSchemasElement>;
}

/**
 * Check if a given object implements the WorkZone interface.
 */
export function instanceOfWorkZone(value: object): value is WorkZone {
  if (!('edges' in value) || value['edges'] === undefined) return false;
  if (!('nodes' in value) || value['nodes'] === undefined) return false;
  return true;
}

export function WorkZoneFromJSON(json: any): WorkZone {
  return WorkZoneFromJSONTyped(json, false);
}

export function WorkZoneFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkZone {
  if (json == null) {
    return json;
  }
  return {
    edges: (json['edges'] as Array<any>).map(WorkZoneEdgesInnerFromJSON),
    nodes: (json['nodes'] as Array<any>).map(AppProjectsTechprocessWorkzoneSchemasElementFromJSON)
  };
}

export function WorkZoneToJSON(value?: WorkZone | null): any {
  if (value == null) {
    return value;
  }
  return {
    edges: (value['edges'] as Array<any>).map(WorkZoneEdgesInnerToJSON),
    nodes: (value['nodes'] as Array<any>).map(AppProjectsTechprocessWorkzoneSchemasElementToJSON)
  };
}
