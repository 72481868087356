import {cast, Instance, types} from 'mobx-state-tree';

import {ResetModel} from 'core/models';
import {ILogPoint} from 'core/interfaces';

const Logger = types
  .compose(
    ResetModel,
    types.model('Logger', {
      isOpen: false,
      points: types.optional(types.array(types.frozen<ILogPoint>()), [])
    })
  )
  .actions((self) => ({
    toggleOpen(): void {
      self.isOpen = !self.isOpen;
    },
    setPoints(points: ILogPoint[]): void {
      self.points = cast(points);
    },
    clearPoints(): void {
      self.points = cast([]);
    }
  }))
  .views(() => ({}));

export type TLoggerModel = Instance<typeof Logger>;

export {Logger};
