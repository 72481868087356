import {FC, PropsWithChildren, ReactElement} from 'react';
import {Box, Center, Grid, GridItem} from '@chakra-ui/react';
import {useNavigate} from 'react-router';
import {observer} from 'mobx-react-lite';
import {Loader} from '@progress-fe/ui-kit';

import {useStore} from 'core';
import {ProjectTypeEnum} from 'api';
import {getProjectUrl} from 'core/utils';
import {TProjectBaseModel} from 'core/models';

import {CheckpointBar, ProjectMainBar} from './components';

export interface IProjectLayoutProps extends PropsWithChildren {
  project: TProjectBaseModel;
  projectActions?: ReactElement;
  onRunProject: () => Promise<void>;
}

const ProjectLayoutFC: FC<IProjectLayoutProps> = ({
  project,
  projectActions,
  onRunProject,
  children
}) => {
  const {authStore} = useStore();
  const {projectInfo, errors} = project;

  const navigate = useNavigate();

  const handleOpenCheckpoint = (checkpointIndex: number) => {
    const checkpoint = projectInfo?.checkpoints?.[checkpointIndex];
    if (!!projectInfo && !!checkpoint) {
      navigate(getProjectUrl(ProjectTypeEnum.TechProcess, projectInfo.uuid, checkpoint.uuid));
    }
  };

  const handleCreateCheckpoint = async () => {
    const checkpointId = await project.createCheckpoint();
    if (!!projectInfo && !!checkpointId) {
      navigate(getProjectUrl(ProjectTypeEnum.TechProcess, projectInfo.uuid, checkpointId));
    }
  };

  if (project.isLoading || project.isCheckpointCreating || !projectInfo) {
    return (
      <Center h="100vh" bg="darkWhite">
        <Loader />
      </Center>
    );
  }

  return (
    <Box>
      {!!projectInfo && authStore.user && (
        <ProjectMainBar
          title={projectInfo.name}
          currentUser={authStore.user}
          isError={errors.length > 0}
          isRunning={project.isRunning}
          lastSaveDate={project.checkpointLastSaving}
          additionalActions={projectActions}
          onSignOut={authStore.signOut}
          onRunCalculation={onRunProject}
          onToggleLog={project.logger.toggleOpen}
          onSave={() => {}}
        />
      )}

      {project.isRunning && <Loader fullScreen />}

      <Grid position="relative" h="var(--height-layout)" gridTemplateColumns={'48px 1fr'}>
        <GridItem bg="bg" borderRight="1px" borderColor="border">
          <CheckpointBar
            activeIndex={project.checkpointIndex}
            checkpointCount={projectInfo.checkpoints.length}
            onSwitchToIndex={handleOpenCheckpoint}
            onCreateCheckpoint={handleCreateCheckpoint}
          />
        </GridItem>
        <GridItem>{children}</GridItem>
      </Grid>
    </Box>
  );
};

export const ProjectLayout = observer(ProjectLayoutFC);
