/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {Position} from './Position';
import {PositionFromJSON, PositionFromJSONTyped, PositionToJSON} from './Position';
import type {ERFElement} from './ERFElement';
import {ERFElementFromJSON, ERFElementFromJSONTyped, ERFElementToJSON} from './ERFElement';
import type {ElementData} from './ElementData';
import {ElementDataFromJSON, ElementDataFromJSONTyped, ElementDataToJSON} from './ElementData';

/**
 *
 * @export
 * @interface AppProjectsTechprocessWorkzoneSchemasElement
 */
export interface AppProjectsTechprocessWorkzoneSchemasElement {
  /**
   *
   * @type {string}
   * @memberof AppProjectsTechprocessWorkzoneSchemasElement
   */
  id: string;
  /**
   *
   * @type {ElementData}
   * @memberof AppProjectsTechprocessWorkzoneSchemasElement
   */
  data: ElementData;
  /**
   *
   * @type {ERFElement}
   * @memberof AppProjectsTechprocessWorkzoneSchemasElement
   */
  type: ERFElement;
  /**
   *
   * @type {Position}
   * @memberof AppProjectsTechprocessWorkzoneSchemasElement
   */
  position: Position;
  /**
   *
   * @type {boolean}
   * @memberof AppProjectsTechprocessWorkzoneSchemasElement
   */
  deletable?: boolean;
}

/**
 * Check if a given object implements the AppProjectsTechprocessWorkzoneSchemasElement interface.
 */
export function instanceOfAppProjectsTechprocessWorkzoneSchemasElement(
  value: object
): value is AppProjectsTechprocessWorkzoneSchemasElement {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('data' in value) || value['data'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('position' in value) || value['position'] === undefined) return false;
  return true;
}

export function AppProjectsTechprocessWorkzoneSchemasElementFromJSON(
  json: any
): AppProjectsTechprocessWorkzoneSchemasElement {
  return AppProjectsTechprocessWorkzoneSchemasElementFromJSONTyped(json, false);
}

export function AppProjectsTechprocessWorkzoneSchemasElementFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AppProjectsTechprocessWorkzoneSchemasElement {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    data: ElementDataFromJSON(json['data']),
    type: ERFElementFromJSON(json['type']),
    position: PositionFromJSON(json['position']),
    deletable: json['deletable'] == null ? undefined : json['deletable']
  };
}

export function AppProjectsTechprocessWorkzoneSchemasElementToJSON(
  value?: AppProjectsTechprocessWorkzoneSchemasElement | null
): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    data: ElementDataToJSON(value['data']),
    type: ERFElementToJSON(value['type']),
    position: PositionToJSON(value['position']),
    deletable: value['deletable']
  };
}
