import {FC, memo, useMemo} from 'react';
import {Tooltip} from 'react-tooltip';
import {Box, Text, useToken} from '@chakra-ui/react';

interface IProps {
  name?: string | null;
}

const TOOLTIP_ID = 'JS_FIELD_NAME_ID';

const JsFieldNameFC: FC<IProps> = ({name}) => {
  const [borderHex, darkWhiteHex] = useToken('colors', ['border', 'darkWhite']);

  const tooltipId = useMemo(() => `${TOOLTIP_ID}_${name}`, [name]);

  return (
    <>
      <Text
        fontSize="12px"
        color="bodyText"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        <span data-tooltip-id={tooltipId}>{name}</span>
      </Text>

      <Tooltip
        id={tooltipId}
        delayShow={250}
        border={`1px solid ${borderHex}`}
        closeEvents={{click: true, mouseleave: true}}
        style={{padding: '0', backgroundColor: darkWhiteHex, zIndex: 1}}
        place={'bottom'}
        opacity="1"
      >
        <Box color="bodyText" p="6px 10px" fontSize="12px">
          {name}
        </Box>
      </Tooltip>
    </>
  );
};

export const JsFieldName = memo(JsFieldNameFC);
