import {FC, memo, ReactNode} from 'react';
import ShowMoreText from 'react-show-more-text';
import {useT} from '@progress-fe/core';

interface IProps {
  lines?: number;
  children: ReactNode;
}

import s from './ShowMore.module.css';

const ShowMoreFC: FC<IProps> = ({lines = 3, children}) => {
  const {t} = useT();

  return (
    <ShowMoreText
      lines={lines}
      expanded={false}
      more={t('actions.showMore')}
      less={t('actions.showLess')}
      truncatedEndingComponent={'... '}
      anchorClass={s.anchor}
    >
      {children}
    </ShowMoreText>
  );
};

export const ShowMore = memo(ShowMoreFC);
