import {FC, useEffect, useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Svg} from '@progress-fe/ui-kit';
import {
  Box,
  Flex,
  Step,
  Center,
  Stepper,
  IconButton,
  StepStatus,
  StepIndicator,
  StepSeparator,
  useSteps,
  useToken
} from '@chakra-ui/react';

interface IProps {
  checkpointCount: number;
  activeIndex: number;
  onSwitchToIndex: (index: number) => void;
  onCreateCheckpoint: () => void;
}

const HISTORY_MAX_COUNT = 10;
const STEP_INDICATOR_SIZE = 12;
const STEP_SEPARATOR_SIZE = 16;

const CheckpointBarFC: FC<IProps> = ({
  activeIndex,
  checkpointCount,
  onSwitchToIndex,
  onCreateCheckpoint
}) => {
  const {activeStep, setActiveStep} = useSteps({count: checkpointCount});

  const [accentHex] = useToken('colors', ['accent']);

  useEffect(() => {
    setActiveStep(activeIndex);
  }, [activeIndex, setActiveStep]);

  const steps = useMemo(() => {
    const stepList: string[] = [];
    for (let i = 0; i < checkpointCount; i++) {
      stepList.push(`${i}`);
    }
    return stepList;
  }, [checkpointCount]);

  const stepperSize = useMemo(() => {
    return steps.length * STEP_INDICATOR_SIZE + (steps.length - 1) * STEP_SEPARATOR_SIZE;
  }, [steps.length]);

  return (
    <Flex flexDirection="column" gap="8px" p="8px 0">
      <Center>
        <IconButton aria-label="" size="sm" variant="ghostTr" icon={<Svg name="RightDouble" />} />
      </Center>

      <Center p="6px 0">
        <Stepper
          gap="0"
          size="sm"
          index={activeStep}
          orientation="vertical"
          height={`${stepperSize}px`}
        >
          {steps.map((_, index) => (
            <Step key={index} style={{position: 'relative'}}>
              <StepIndicator
                sx={{zIndex: 1}}
                onClick={() => {
                  if (activeStep !== index) {
                    onSwitchToIndex(index);
                  }
                }}
              >
                <StepStatus />
              </StepIndicator>
              <StepSeparator style={{zIndex: 1}} />

              {index === activeStep && (
                <Box
                  position="absolute"
                  borderRadius="4px"
                  bg={accentHex}
                  width="32px"
                  height="32px"
                  left="-10px"
                  top="-10px"
                  opacity="0.1"
                  zIndex={0}
                />
              )}
            </Step>
          ))}
        </Stepper>
      </Center>

      <Center>
        <IconButton
          size="sm"
          aria-label=""
          variant="ghostTr"
          icon={<Svg name="Plus" />}
          isDisabled={checkpointCount >= HISTORY_MAX_COUNT}
          onClick={onCreateCheckpoint}
        />
      </Center>
    </Flex>
  );
};

export const CheckpointBar = observer(CheckpointBarFC);
