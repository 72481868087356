/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ProjectTypeEnum} from './ProjectTypeEnum';
import {
  ProjectTypeEnumFromJSON,
  ProjectTypeEnumFromJSONTyped,
  ProjectTypeEnumToJSON
} from './ProjectTypeEnum';
import type {CheckpointOut} from './CheckpointOut';
import {
  CheckpointOutFromJSON,
  CheckpointOutFromJSONTyped,
  CheckpointOutToJSON
} from './CheckpointOut';

/**
 *
 * @export
 * @interface ProjectOut
 */
export interface ProjectOut {
  /**
   *
   * @type {string}
   * @memberof ProjectOut
   */
  name: string;
  /**
   *
   * @type {ProjectTypeEnum}
   * @memberof ProjectOut
   */
  type: ProjectTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ProjectOut
   */
  uuid: string;
  /**
   *
   * @type {Date}
   * @memberof ProjectOut
   */
  updatedAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ProjectOut
   */
  createdAt: Date;
  /**
   *
   * @type {boolean}
   * @memberof ProjectOut
   */
  deleted: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProjectOut
   */
  isFavorite: boolean;
  /**
   *
   * @type {Array<CheckpointOut>}
   * @memberof ProjectOut
   */
  checkpoints: Array<CheckpointOut>;
}

/**
 * Check if a given object implements the ProjectOut interface.
 */
export function instanceOfProjectOut(value: object): value is ProjectOut {
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('uuid' in value) || value['uuid'] === undefined) return false;
  if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
  if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
  if (!('deleted' in value) || value['deleted'] === undefined) return false;
  if (!('isFavorite' in value) || value['isFavorite'] === undefined) return false;
  if (!('checkpoints' in value) || value['checkpoints'] === undefined) return false;
  return true;
}

export function ProjectOutFromJSON(json: any): ProjectOut {
  return ProjectOutFromJSONTyped(json, false);
}

export function ProjectOutFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectOut {
  if (json == null) {
    return json;
  }
  return {
    name: json['name'],
    type: ProjectTypeEnumFromJSON(json['type']),
    uuid: json['uuid'],
    updatedAt: new Date(json['updated_at']),
    createdAt: new Date(json['created_at']),
    deleted: json['deleted'],
    isFavorite: json['is_favorite'],
    checkpoints: (json['checkpoints'] as Array<any>).map(CheckpointOutFromJSON)
  };
}

export function ProjectOutToJSON(value?: ProjectOut | null): any {
  if (value == null) {
    return value;
  }
  return {
    name: value['name'],
    type: ProjectTypeEnumToJSON(value['type']),
    uuid: value['uuid'],
    updated_at: value['updatedAt'].toISOString(),
    created_at: value['createdAt'].toISOString(),
    deleted: value['deleted'],
    is_favorite: value['isFavorite'],
    checkpoints: (value['checkpoints'] as Array<any>).map(CheckpointOutToJSON)
  };
}
