import {Flex, IconButton, InputGroup, InputRightAddon} from '@chakra-ui/react';
import {FC, memo, useEffect, useState} from 'react';

import {Svg} from '../../helpers';
import {InputNumber} from '../InputNumber';

interface IProps {
  value: number;
  max?: number;
  min?: number;
  isReadonly?: boolean;
  onChange: (value: number) => void;
}

const NumberStepperFC: FC<IProps> = ({isReadonly, value, max, min = 0, onChange}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const increment = () => {
    const newValue = currentValue + 1;

    if (max && newValue > max) {
      return;
    }

    setCurrentValue(newValue);
    onChange(newValue);
  };

  const decrement = () => {
    const newValue = currentValue - 1;

    if (newValue < min) {
      return;
    }

    setCurrentValue(newValue);
    onChange(newValue);
  };

  return (
    <InputGroup size="xs">
      <InputNumber
        min={min}
        max={max}
        size="xs"
        variant="outline"
        isOnChangeOnlyOnBlur
        disabled={true}
        value={currentValue}
      />
      <InputRightAddon bg="darkWhite">
        <Flex gap="8px">
          <IconButton
            size="2sm"
            aria-label=""
            variant="transparent"
            isDisabled={isReadonly}
            icon={<Svg size="s12" name="Minus" />}
            onClick={() => decrement()}
          />
          <IconButton
            size="2sm"
            aria-label=""
            isDisabled={isReadonly}
            variant="transparent"
            icon={<Svg size="s12" name="Plus" />}
            onClick={() => increment()}
          />
        </Flex>
      </InputRightAddon>
    </InputGroup>
  );
};

export const NumberStepper = memo(NumberStepperFC);
