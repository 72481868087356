/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
/**
 *
 * @export
 * @interface ConnectInfo
 */
export interface ConnectInfo {
  /**
   *
   * @type {string}
   * @memberof ConnectInfo
   */
  elementUuid: string;
  /**
   *
   * @type {string}
   * @memberof ConnectInfo
   */
  portCode: string;
  /**
   *
   * @type {number}
   * @memberof ConnectInfo
   */
  portNumber?: number | null;
}

/**
 * Check if a given object implements the ConnectInfo interface.
 */
export function instanceOfConnectInfo(value: object): value is ConnectInfo {
  if (!('elementUuid' in value) || value['elementUuid'] === undefined) return false;
  if (!('portCode' in value) || value['portCode'] === undefined) return false;
  return true;
}

export function ConnectInfoFromJSON(json: any): ConnectInfo {
  return ConnectInfoFromJSONTyped(json, false);
}

export function ConnectInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectInfo {
  if (json == null) {
    return json;
  }
  return {
    elementUuid: json['element_uuid'],
    portCode: json['port_code'],
    portNumber: json['port_number'] == null ? undefined : json['port_number']
  };
}

export function ConnectInfoToJSON(value?: ConnectInfo | null): any {
  if (value == null) {
    return value;
  }
  return {
    element_uuid: value['elementUuid'],
    port_code: value['portCode'],
    port_number: value['portNumber']
  };
}
