import {cast, flow, Instance, types} from 'mobx-state-tree';
import {IErrorMessage} from '@progress-fe/ui-kit';
import {hasValue} from '@progress-fe/core';
import {v4 as uuidv4} from 'uuid';

import {CheckpointsApi, ProjectOut, ProjectsApi, type ResponseStatusCheckpointOut} from 'api';
import {RequestModel, ResetModel} from 'core/models';
import {PROJECT_LIST} from 'core/mocks/projects.mocks';

import {UiState, Logger} from './models';

const ProjectBase = types
  .compose(
    ResetModel,
    types.model('ProjectBase', {
      isRunning: false,
      isLoading: false,
      isCheckpointCreating: false,

      checkpointUuid: '',
      checkpointLastSaving: types.maybeNull(types.Date),

      logger: types.optional(Logger, {}),
      uiState: types.optional(UiState, {}),
      projectInfo: types.maybeNull(types.frozen<ProjectOut>()),
      errors: types.optional(types.array(types.frozen<IErrorMessage>()), []),

      checkpointRequest: types.optional(RequestModel, {}),
      fetchRequest: types.optional(RequestModel, {}),
      logsRequest: types.optional(RequestModel, {}),
      runCallbackRequest: types.optional(RequestModel, {}),
      runRequest: types.optional(RequestModel, {})
    })
  )
  .volatile<{_lastSaveInterval: NodeJS.Timeout | null}>(() => ({
    _lastSaveInterval: null
  }))
  .actions((self) => ({
    _load: flow(function* (projectUuid: string) {
      // FIXME: Temp. Removal
      const mockProject = PROJECT_LIST.find((p) => p.uuid === projectUuid);
      if (mockProject) {
        return mockProject;
      }

      const response: ProjectOut = yield self.fetchRequest.send(
        ProjectsApi.projectsGetProject.bind(ProjectsApi),
        {
          projectUuid
        }
      );

      return response || null;
    })
  }))
  // TODO: Use sockets
  .actions((self) => ({
    _setCheckpointLastSaving(date: Date): void {
      self.checkpointLastSaving = date;
    },
    _clearIntervals() {
      if (self._lastSaveInterval) {
        clearInterval(self._lastSaveInterval);
      }
    },
    _startFetchLastSave(): void {
      self._lastSaveInterval = setInterval(() => {
        self._load(self.projectInfo?.uuid || '').then((project) => {
          if (!!project) {
            const checkpoint = project.checkpoints.find((c) => c.uuid === self.checkpointUuid);
            if (!!checkpoint) {
              this._setCheckpointLastSaving(checkpoint.lastSavingDt);
            }
          }
        });
      }, 10000);
    }
  }))
  .actions((self) => ({
    _baseInit: flow(function* (projectId: string, checkpointId: string) {
      const project: ProjectOut | null = yield self._load(projectId);
      const checkpoint = project?.checkpoints.find((c) => c.uuid === checkpointId);

      if (project && checkpoint) {
        self.projectInfo = project;
        self.checkpointUuid = checkpoint.uuid;
        self.checkpointLastSaving = checkpoint.lastSavingDt;
        self._startFetchLastSave();
      }
    }),
    _setErrors(errors: IErrorMessage[]): void {
      self.errors = cast(errors);
    }
  }))
  .actions((self) => ({
    createCheckpoint: flow(function* () {
      self.isCheckpointCreating = true;

      const response: ResponseStatusCheckpointOut = yield self.checkpointRequest.send(
        CheckpointsApi.checkpointsCreateCheckpoint.bind(CheckpointsApi),
        {
          projectUuid: self.projectInfo?.uuid || '',
          fromCheckpoint: self.checkpointUuid,
          idempotencyKey: uuidv4()
        }
      );

      return response?.data?.uuid || '';
    })
  }))
  .views((self) => ({
    get projectUuid(): string {
      return self.projectInfo?.uuid || '';
    },
    get checkpointIndex(): number {
      const points = self.projectInfo?.checkpoints;
      const index = points?.findIndex((c) => c.uuid === self.checkpointUuid);
      return hasValue(index) ? index : -1;
    }
  }));

export type TProjectBaseModel = Instance<typeof ProjectBase>;

export {ProjectBase};
