/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ElementGroupNameCode} from './ElementGroupNameCode';
import {
  ElementGroupNameCodeFromJSON,
  ElementGroupNameCodeFromJSONTyped,
  ElementGroupNameCodeToJSON
} from './ElementGroupNameCode';
import type {AppCatalogElementsSchemasElement} from './AppCatalogElementsSchemasElement';
import {
  AppCatalogElementsSchemasElementFromJSON,
  AppCatalogElementsSchemasElementFromJSONTyped,
  AppCatalogElementsSchemasElementToJSON
} from './AppCatalogElementsSchemasElement';

/**
 *
 * @export
 * @interface ElementGroup
 */
export interface ElementGroup {
  /**
   *
   * @type {ElementGroupNameCode}
   * @memberof ElementGroup
   */
  type: ElementGroupNameCode;
  /**
   *
   * @type {string}
   * @memberof ElementGroup
   */
  name: string;
  /**
   *
   * @type {Array<AppCatalogElementsSchemasElement>}
   * @memberof ElementGroup
   */
  elements: Array<AppCatalogElementsSchemasElement>;
}

/**
 * Check if a given object implements the ElementGroup interface.
 */
export function instanceOfElementGroup(value: object): value is ElementGroup {
  if (!('type' in value) || value['type'] === undefined) return false;
  if (!('name' in value) || value['name'] === undefined) return false;
  if (!('elements' in value) || value['elements'] === undefined) return false;
  return true;
}

export function ElementGroupFromJSON(json: any): ElementGroup {
  return ElementGroupFromJSONTyped(json, false);
}

export function ElementGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElementGroup {
  if (json == null) {
    return json;
  }
  return {
    type: ElementGroupNameCodeFromJSON(json['type']),
    name: json['name'],
    elements: (json['elements'] as Array<any>).map(AppCatalogElementsSchemasElementFromJSON)
  };
}

export function ElementGroupToJSON(value?: ElementGroup | null): any {
  if (value == null) {
    return value;
  }
  return {
    type: ElementGroupNameCodeToJSON(value['type']),
    name: value['name'],
    elements: (value['elements'] as Array<any>).map(AppCatalogElementsSchemasElementToJSON)
  };
}
