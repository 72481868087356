/* tslint:disable */
/* eslint-disable */
/**
 * Oil refinery process modeling system
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {mapValues} from '../runtime';
import type {ElementEdgeProperties} from './ElementEdgeProperties';
import {
  ElementEdgePropertiesFromJSON,
  ElementEdgePropertiesFromJSONTyped,
  ElementEdgePropertiesToJSON
} from './ElementEdgeProperties';
import type {EnergyFlowEdgeProperties} from './EnergyFlowEdgeProperties';
import {
  EnergyFlowEdgePropertiesFromJSON,
  EnergyFlowEdgePropertiesFromJSONTyped,
  EnergyFlowEdgePropertiesToJSON
} from './EnergyFlowEdgeProperties';
import type {MaterialFlowEdgeProperties} from './MaterialFlowEdgeProperties';
import {
  MaterialFlowEdgePropertiesFromJSON,
  MaterialFlowEdgePropertiesFromJSONTyped,
  MaterialFlowEdgePropertiesToJSON
} from './MaterialFlowEdgeProperties';
import type {MarkerProperties} from './MarkerProperties';
import {
  MarkerPropertiesFromJSON,
  MarkerPropertiesFromJSONTyped,
  MarkerPropertiesToJSON
} from './MarkerProperties';

/**
 *
 * @export
 * @interface WorkZoneEdgesInner
 */
export interface WorkZoneEdgesInner {
  /**
   *
   * @type {string}
   * @memberof WorkZoneEdgesInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof WorkZoneEdgesInner
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof WorkZoneEdgesInner
   */
  target: string;
  /**
   *
   * @type {string}
   * @memberof WorkZoneEdgesInner
   */
  sourceHandle?: string;
  /**
   *
   * @type {string}
   * @memberof WorkZoneEdgesInner
   */
  targetHandle?: string;
  /**
   *
   * @type {boolean}
   * @memberof WorkZoneEdgesInner
   */
  reconnectable?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof WorkZoneEdgesInner
   */
  deletable?: boolean;
  /**
   *
   * @type {object}
   * @memberof WorkZoneEdgesInner
   */
  style?: object;
  /**
   *
   * @type {MarkerProperties}
   * @memberof WorkZoneEdgesInner
   */
  markerEnd?: MarkerProperties;
  /**
   *
   * @type {string}
   * @memberof WorkZoneEdgesInner
   */
  type?: WorkZoneEdgesInnerTypeEnum;
}

/**
 * @export
 */
export const WorkZoneEdgesInnerTypeEnum = {
  Material: 'material',
  Energy: 'energy',
  UnknownDefaultOpenApi: '11184809'
} as const;
export type WorkZoneEdgesInnerTypeEnum =
  (typeof WorkZoneEdgesInnerTypeEnum)[keyof typeof WorkZoneEdgesInnerTypeEnum];

/**
 * Check if a given object implements the WorkZoneEdgesInner interface.
 */
export function instanceOfWorkZoneEdgesInner(value: object): value is WorkZoneEdgesInner {
  if (!('id' in value) || value['id'] === undefined) return false;
  if (!('source' in value) || value['source'] === undefined) return false;
  if (!('target' in value) || value['target'] === undefined) return false;
  return true;
}

export function WorkZoneEdgesInnerFromJSON(json: any): WorkZoneEdgesInner {
  return WorkZoneEdgesInnerFromJSONTyped(json, false);
}

export function WorkZoneEdgesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): WorkZoneEdgesInner {
  if (json == null) {
    return json;
  }
  return {
    id: json['id'],
    source: json['source'],
    target: json['target'],
    sourceHandle: json['sourceHandle'] == null ? undefined : json['sourceHandle'],
    targetHandle: json['targetHandle'] == null ? undefined : json['targetHandle'],
    reconnectable: json['reconnectable'] == null ? undefined : json['reconnectable'],
    deletable: json['deletable'] == null ? undefined : json['deletable'],
    style: json['style'] == null ? undefined : json['style'],
    markerEnd: json['markerEnd'] == null ? undefined : MarkerPropertiesFromJSON(json['markerEnd']),
    type: json['type'] == null ? undefined : json['type']
  };
}

export function WorkZoneEdgesInnerToJSON(value?: WorkZoneEdgesInner | null): any {
  if (value == null) {
    return value;
  }
  return {
    id: value['id'],
    source: value['source'],
    target: value['target'],
    sourceHandle: value['sourceHandle'],
    targetHandle: value['targetHandle'],
    reconnectable: value['reconnectable'],
    deletable: value['deletable'],
    style: value['style'],
    markerEnd: MarkerPropertiesToJSON(value['markerEnd']),
    type: value['type']
  };
}
