import {FC, memo} from 'react';
import {Box, Flex} from '@chakra-ui/react';
import {useT} from '@progress-fe/core';

interface IProps {
  propName: string;
}

const JsFieldErrorFC: FC<IProps> = ({propName}) => {
  const {t} = useT();

  return (
    <Box p="4px" border="1px solid" borderRadius="4px" borderColor="error">
      <Flex h="64px" alignItems="center" justifyContent="center">
        {t('error.fieldValue')} {propName}
      </Flex>
    </Box>
  );
};

export const JsFieldError = memo(JsFieldErrorFC);
