import {memo} from 'react';
import {cloneDeep} from 'lodash-es';
import {FieldProps} from '@rjsf/utils';
import {Box, Button, Flex, Grid} from '@chakra-ui/react';
import {EventEmitter, useT} from '@progress-fe/core';

import {JsFieldName} from '../../jsFormBase';
import {ISelectOption} from '../../../interfaces';

import {TJsPortOption, TJsConnectionFormData, TJsPortOptionValue} from './ConnectionsJsField.types';
import {SelectNode} from './components';

/**
 * Component renders "/schemas/jsf-connections" field of JsonSchema.
 * @param props : field props come from JsonSchema.
 * Value of props.formData is an instance of TNodeHandleFormData[].
 */
const ConnectionsJsFieldFC = (props: FieldProps) => {
  const formData = cloneDeep(props.formData) as Array<TJsConnectionFormData>;

  const {t} = useT();

  const buildUniqueOptionValue = (optionValue: TJsPortOptionValue): string => {
    return `${optionValue.elementId}|${optionValue.portCode}`;
  };

  const parseUniqueOptionValue = (optionValueString: string): TJsPortOptionValue => {
    const parts = optionValueString.split('|');
    return {elementId: parts[0] || '', portCode: parts[1] || ''};
  };

  const handleChange = (index: number, valueIndex: number, option: ISelectOption<string>) => {
    const portValue: TJsPortOptionValue = parseUniqueOptionValue(option.value);

    const formDataCopy = cloneDeep(formData);
    formDataCopy[index].values[valueIndex] = {
      elementId: portValue.elementId,
      portCode: portValue.portCode
    };

    props.onChange(formDataCopy);
  };

  const handleEmptyConnection = (targetIndex: number) => {
    const formDataCopy = [...formData];
    formDataCopy[targetIndex].values.push(null);
    props.onChange(formDataCopy);
  };

  const mapSelectOptions = (options: Array<TJsPortOption>): ISelectOption<string>[] => {
    return options.map((opt) => ({
      value: buildUniqueOptionValue(opt),
      label: opt.elementName
    }));
  };

  const handleSelectOrPickElement = (optionValueString: string, isPick: boolean) => {
    const portValue: TJsPortOptionValue = parseUniqueOptionValue(optionValueString);
    if (isPick) {
      EventEmitter.emit('PickItem', portValue.elementId);
    } else {
      EventEmitter.emit('SelectItem', portValue.elementId);
    }
  };

  return (
    <Box data-testid="ConnectionsJsField-test">
      <Flex flexDirection="column" gap="4px">
        {formData.length === 0 ? (
          <Box borderRadius="4px" border="1px solid" borderColor="border">
            <Flex h="64px" alignItems="center" justifyContent="center">
              {t('connections.noConnections')}
            </Flex>
          </Box>
        ) : (
          <>
            {formData.map((item, index) => {
              const options = mapSelectOptions(item.options);
              return (
                <Grid key={item.name} gridTemplateColumns="minmax(0, 180px) 1fr" gap="4px">
                  <Box p="3px 0 0 0">
                    <JsFieldName name={item.name} />
                  </Box>
                  <Box>
                    {item.capacitance === 1 ? (
                      <SelectNode
                        name={item.name}
                        options={options}
                        value={item.values[0] ? buildUniqueOptionValue(item.values[0]) : undefined}
                        isDisabled={props.schema.readOnly}
                        onChange={(selected) => handleChange(index, 0, selected)}
                        onSelect={(id) => handleSelectOrPickElement(id, false)}
                        onPick={(id) => handleSelectOrPickElement(id, true)}
                      />
                    ) : (
                      <Flex flexDirection="column" gap="4px">
                        {item.values.map((valueItem, valueIndex) => (
                          <SelectNode
                            key={`${index}${valueIndex}`}
                            name={`${item.name}${valueIndex}`}
                            value={!!valueItem ? buildUniqueOptionValue(valueItem) : undefined}
                            options={options}
                            isDisabled={props.schema.readOnly}
                            onChange={(selected) => handleChange(index, valueIndex, selected)}
                            onSelect={(id) => handleSelectOrPickElement(id, false)}
                            onPick={(id) => handleSelectOrPickElement(id, true)}
                          />
                        ))}

                        {/* ADDING A NEW CONNECTION TO EXISTING ELEMENT */}
                        {!props.schema.readOnly && item.values.length < item.capacitance && (
                          <Button
                            size="smSquare"
                            width="100%"
                            variant="ghost"
                            onClick={() => handleEmptyConnection(index)}
                          >
                            {t('actions.add')}
                          </Button>
                        )}
                      </Flex>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </>
        )}
      </Flex>
    </Box>
  );
};

export const ConnectionsJsField = memo(ConnectionsJsFieldFC);
